import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper components
import "swiper/swiper-bundle.css"; // Import Swiper styles
// import "react-loading-skeleton/dist/skeleton.css";
import CardSkeletonLoader from "../elements/loader/CardSkeletonLoader";
import SingleService from "./SingleService";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";

const ServiceCard = ({ data }) => {
  const swiperParams = {
    pagination: {
      clickable: true,
      dynamicBullets: true,
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      1280: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1900: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    },
  };
  //   console.log(data, "datata");

  return (
    <section className="relative overflow-hidden w-full py-20 bg-gray-100">
      <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
        <div className="space-y-10">
          <div className="space-y-4 text-center">
            <h2 className="font-Lexend text-3xl sm:text-4xl xl:text-5xl font-bold text-black">
              Services
            </h2>
            {/* <div className="text-sm xl:text-base text-slate-600 max-w-xl mx-auto">{data?.subtitle}</div> */}
          </div>
          {data?.loaded ? (
            data?.subdata &&
            data?.subdata?.length > 0 && (
              <div className="relative">
                <Swiper
                  autoplay={{ delay: 9000, disableOnInteraction: false }}
                  modules={[Navigation, Autoplay]}
                  {...swiperParams}
                  className="pb-10 topFundraiser"
                >
                  {data?.subdata?.map((item) => (
                    <SwiperSlide key={item._id} className="!h-auto flex">
                      <SingleService data={item} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )
          ) : (
            <div className="flex gap-4 justify-center">
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
              <CardSkeletonLoader />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ServiceCard;
