import React, { useState } from "react";
import { classNames } from "../helpers/classNames";
import Image from "../components/elements/Image";
import Contactimage from "../assets/images/contact.jpg";
import Input from "../components/form/Input";
import SelectDropdownSearch from "../components/form/SelectDropdownSearch";
import TextAreaAutoSize from "../components/form/TextareaAutoSize";
import Button from "../components/form/Button";
import useForm from "../hooks/useForm";
import { addContact } from "../services/HomeService";
import { toast } from "react-toastify";
import { formValidate } from "../helpers/formValidate";

const Contact = () => {
  const validation = {
    name: { required: true, message: "Please enter your name !!!" },
    address: { required: true, message: "Please enter your address !!!" },
    email: { required: true, message: "Please enter your email !!!" },
    phone: { required: true, message: "Please enter your phone !!!" },
    service: { required: true, message: "Please enter your service !!!" },
  };
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldsValue,
    resetField,
  } = useForm({}, validation);
  const [message, setMessage] = useState("");
  const [loaded, setLoaded] = useState(false);
  const menu = [
    {
      _id: 1,
      name: "Commercial Painting Contractors",
      link: "/service",
    },
    {
      _id: 2,
      name: "Coving Services",
      link: "/service",
    },
    {
      _id: 3,
      name: "Decorating Services",
      link: "/service",
    },
    {
      _id: 4,
      name: "Exterior Painting",
      link: "/service",
    },
    {
      _id: 5,
      name: "Exterior Renderings",
      link: "/service",
    },
    {
      _id: 6,
      name: "Interior Painting",
      link: "/service",
    },
    {
      _id: 7,
      name: "Sash Window Replacement",
      link: "/service",
    },
    {
      _id: 8,
      name: "Sash Window Restoration",
      link: "/service",
    },
    {
      _id: 9,
      name: "Sash Window Repair",
      link: "/service",
    },
    {
      _id: 11,
      name: "Laminated Flooring",
      link: "/service",
    },
    { _id: 12, name: "Local Builders", link: "/service" },
    { _id: 13, name: "London Underground Refurbishments", link: "/service" },
    { _id: 14, name: "Mastic Services", link: "/service" },
    { _id: 15, name: "Painting Contractors", link: "/service" },
    { _id: 16, name: "Plastering Services", link: "/service" },
    { _id: 17, name: "Residential Decorating", link: "/service" },
    { _id: 19, name: "Skimming Specialists", link: "/service" },
    { _id: 18, name: "Tiling Services", link: "/service" },
    { _id: 20, name: "Wallpapering Services", link: "/service" },
  ];

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await addContact({
        name: values?.name,
        lastName: values?.lastName,
        email: values?.email,
        phone: values?.phone,
        message: message,
        service: values?.service?._id,
      });
      toast?.success("Data inserted successfully")
      // window.location.reload();
      // if (res?.data?.status === 200 || res?.data?.status === 201) {
      //   toast.success(res?.data?.message);
      //   resetField({
      //     name: "",
      //     address: "",
      //     email: "",
      //     phone: "",
      //     service: "",
      //   });
      //   setMessage("");
      // } else {
      //   toast.info(
      //     res?.data?.message || res?.data?.errors || "Something went wrong"
      //   );
      // }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <>
      <div
        className={classNames(
          "relative z-[2] w-full h-[200px] lg:h-[300px] xl:h-[400px] overflow-hidden rounded-b-3xl mb-10",
          "after:content-[''] after:absolute after:w-full after:h-full after:bg-gradient-to-t after:from-black/80 after:bottom-0 after:left-0 after:z-[1]"
        )}
      >
        <Image
          src={Contactimage}
          alt={""}
          width={"100%"}
          height={"100%"}
          effect={"blur"}
          className={" object-cover object-top"}
        />
        <h4 className="absolute left-0 right-0 mx-auto text-center text-white text-lg lg:text-xl xl:text-3xl capitalize bottom-20 lg:bottom:20 xl:bottom-40 z-10 font-bold">
          Contact Us
        </h4>
      </div>

      <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
        <div className="bg-[#efefef] px-10 py-16 -top-16 rounded-xl relative z-[9]">
          <div className="text-3xl text-black font-extrabold mb-5 text-center">
            Get In Touch!
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 items-center">
            <div className="bg-[#1e1e1e] p-6 text-center rounded-lg h-60 border border-carnation-400">
              <div className="text-center text-yellow-600 bg-white rounded-full w-16 h-16 text-xl pt-5 mx-auto">
                <i class="fa-solid fa-phone-volume"></i>
              </div>
              <div className="text-white text-xl mt-5 font-bold underline">
                Phone
              </div>
              <div className="text-white text-xl mt-2">020 7169 4208</div>
              <div className="text-white text-xl">07868 729 221</div>
            </div>
            <div className="bg-[#1e1e1e] p-6 text-center rounded-lg h-60">
              <div className="text-center text-yellow-600 bg-white rounded-full w-16 h-16 text-xl pt-5 mx-auto">
                <i class="fa-solid fa-envelope"></i>
              </div>
              <div className="text-white text-xl mt-5 font-bold underline">
                Mail
              </div>
              <div className="text-white text-xl mt-2 line-clamp-2">
                info@ashmoredecorators.co.uk
              </div>
            </div>
            <div className="bg-[#1e1e1e] p-6 text-center rounded-lg h-60">
              <div className="text-center text-yellow-600 bg-white rounded-full w-16 h-16 text-xl pt-5 mx-auto">
                <i class="fa-sharp fa-solid fa-location-dot"></i>
              </div>
              <div className="text-white text-xl mt-5 font-bold underline">
                Address
              </div>
              <div className="text-white text-xl mt-2">
                74 Ashmore Rd, London, W9 3DG
              </div>
            </div>
          </div>
          <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-12">
                <div className="text-3xl text-black font-extrabold mb-5 text-center mt-20">
                  Have Questions? Get In Touch!
                </div>
              </div>
              <div className="col-span-12 xl:col-span-6">
                <Input
                  label={"Name"}
                  inputClasses={"!h-14 !mb-3 !bg-white"}
                  inputPlaceholder={"Enter Your Name"}
                  inputType={"text"}
                  value={values.name}
                  inputName={"name"}
                  onChange={handleChange}
                  {...formValidate(errors, "name")}
                />
              </div>
              <div className="col-span-12 xl:col-span-6">
                <Input
                  label={"Phone Number"}
                  inputClasses={"!h-14 !mb-3 !bg-white"}
                  inputPlaceholder={"Enter Your Valid Phone Number"}
                  inputType={"text"}
                  value={values.phone}
                  inputName={"phone"}
                  onChange={handleChange}
                  {...formValidate(errors, "phone")}
                />
              </div>
              <div className="col-span-12">
                <Input
                  label={"Email"}
                  inputClasses={"!h-14 !mb-3 !bg-white"}
                  inputPlaceholder={"Enter Your Email"}
                  inputType={"text"}
                  value={values.email}
                  inputName={"email"}
                  onChange={handleChange}
                  {...formValidate(errors, "email")}
                />
              </div>
              <div className="col-span-12 xl:col-span-6">
                <Input
                  label={"Address"}
                  inputClasses={"!h-14 !mb-3 !bg-white"}
                  inputPlaceholder={"Enter Your Address"}
                  inputType={"text"}
                  value={values.address}
                  inputName={"address"}
                  onChange={handleChange}
                  {...formValidate(errors, "address")}
                />
              </div>
              <div className="col-span-12 xl:col-span-6">
                <SelectDropdownSearch
                  label={"Service"}
                  inputClasses={"!h-14 !mb-3 !bg-white"}
                  placeholder={"Enter Your Service"}
                  xPlacement={"bottom"}
                  buttonArrowIcon={""}
                  selectName={"service"}
                  isSearch={false}
                  dropdownButtonClass="!px-3  !border-gray-200 !w-full"
                  onChange={handleChange}
                  value={values?.service}
                  dropdownData={menu?.map((item) => ({
                    name: item?.name,
                    _id: item?.name,
                  }))}
                  {...formValidate(errors, "services")}
                />
              </div>
              <div className="col-span-12">
                <TextAreaAutoSize
                  label={"Message"}
                  inputClasses={"!mb-3 !bg-white"}
                  inputPlaceholder={"Write Your Message"}
                  inputValue={message}
                  inputName={"message"}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
              <div className="col-span-12">
                <Button
                  buttonLabel={"Send"}
                  buttonLabelClasses={"!text-lg"}
                  buttonClasses={
                    "!px-4 hover:!bg-black transition-all duration-300 !rounded-md !text-sm "
                  }
                  buttonIcon={"fa-solid fa-paper-plane-top"}
                  buttonIconPosition={"left"}
                  buttonType={"submit"}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="relative">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9928.911967224667!2d-0.202978!3d51.527378!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761010f83be47b%3A0x8f4310663021c6ea!2sAshmore%20Decorators%20Ltd!5e0!3m2!1sen!2sin!4v1720822698547!5m2!1sen!2sin"
          width="100%"
          height="400"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default Contact;
