import React from 'react';

const About = ({data}) => {

  return (
    <>
      <div className="relative space-y-2 xl:space-y-5">
        <h4 className="text-lg font-semibold text-black">{data.title}</h4>
        <div className="text-[13px] xl:text-sm text-slate-600 space-y-4" dangerouslySetInnerHTML={{__html: data.content}}></div>
      </div>
    </>
  );
};

export default About;