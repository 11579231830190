import React from "react";
import { classNames } from "../helpers/classNames";
import Image from "../components/elements/Image";
import Servicebg from ".././../src/assets/images/ser_bg.jpg";
import Commercial_Painting_Contractors from ".././../src/assets/images/commercial-painting-contractors.jpg";
import Coving_Services from ".././../src/assets/images/coving-services.jpg";
import Decorating_Services from ".././../src/assets/images/decorating-services.jpg";
import Exterior_Painting from ".././../src/assets/images/exterior-painting.webp";
import Exterior_Rendering from ".././../src/assets/images/exterior-rendering.jpg";
import Interior_Painting from ".././../src/assets/images/interior-painting.webp";
import Laminated_Flooring from ".././../src/assets/images/laminated-flooring.jpg";
import Local_Builders from ".././../src/assets/images/local-builders.jpg";
import London_Underground_Refurbishments from ".././../src/assets/images/london-underground-refurbishments.jpg";
import Mastic_Services from ".././../src/assets/images/mastic-services.jpg";
import Painting_Contractors from ".././../src/assets/images/painting-contractors.jpg";
import Plastering_Services from ".././../src/assets/images/plastering-services.jpg";
import Residential_Decorating from ".././../src/assets/images/residential-decorating.webp";
import Sash_Window_Repair from ".././../src/assets/images/sash-window-repair.jpg";
import Sash_Window_Replacement from ".././../src/assets/images/sash-window-replacement.jpg";
import Sash_Window_Restoration from ".././../src/assets/images/sash-window-restoration.jpg";
import Skimming_Specialists from ".././../src/assets/images/skimming-specialists.jpg";
import Tiling_Services from ".././../src/assets/images/tiling-services.jpg";
import Wallpapering_Services from ".././../src/assets/images/wallpapering-services.jpg";
import {
  Link,
  useNavigate,
} from "react-router-dom/dist/umd/react-router-dom.development";

const Service = () => {
  const navigate = useNavigate();

  const services = 
  [
    {
      _id: 1,
      name: "Commercial Painting Contractors",
      slug: "commercial-painting-contractors",
      description:
        "We offer a commercial and industrial painting service across London. Our painting services cover almost all forms of commercial properties including shops and retail outlets, offices, factories, hotels, petrol stations, bars, underground stations, and restaurants. Our expert team also offers domestic painting and decorating services.",
      image: Commercial_Painting_Contractors,
      servicelinktitle: "Read More",
    },
    {
      _id: 2,
      name: "Coving Services",
      slug: "coving-services",
      description:
        "Ashmore Decorators’s qualified plasterers perform a range of plastering services including internal coving to make the most of your property. You can rely on our time-served tradesmen to carry out professional plastering, rendering, and coving services to the highest of standards. We pride ourselves on always finishing the work on time, leaving your property clean and tidy.",
      image: Coving_Services,
      servicelinktitle: "Read More",
    },
    {
      _id: 3,
      name: "Decorating Services",
      slug: "decorating-services",
      description:
        "From extensive makeovers to subtle changes, our decorators can achieve the desired result. We will turn your ideas into reality, sharing our advice along the way. We pay great attention to detail to every step of the process. You can expect high standards at every stage. Interior decorating service Ashmore Decorators’ interior decorating and finishing work is delivered by specialists with years of experience. Exterior decorating services When you want to attract more customers to your business, having a clean well-decorated premises will encourage people to step inside. Ashmore Decorators have many years’ experience in domestic, commercial and retail decoration and can make your building’s exterior look absolutely fabulous.",
      image: Decorating_Services,
      servicelinktitle: "Read More",
    },
    {
      _id: 4,
      name: "Exterior Painting",
      slug: "exterior-painting",
      description:
        "The exterior of your property is the first impression that visitors will form of you and your home or business, and as experienced painters, we are here to make certain that first impression is a good one! Building exteriors are subject to damage by weather as well as pollution and grime. Our professionals are experts in providing exterior painting services across London.",
      image: Exterior_Painting,
      servicelinktitle: "Read More",
    },
    {
      _id: 5,
      name: "Exterior Rendering",
      slug: "exterior-rendering",
      description:
        "Improve the look of your home instantly with external plastering and rendering, a process for evening out surfaces in preparation for you painting your property. Ashmore Decorators specialises in external plastering and rendering, giving your property protection against the elements all year round. Our qualified plasterers have the skills and experience to work with a wide choice of materials to give you a personal finish.",

      image: Exterior_Rendering,
      servicelinktitle: "Read More",
    },
    {
      _id: 6,
      name: "Interior Painting",
      slug: "interior-painting",
      description:
        "Ashmore Decorators carries out an extensive range of quality and affordable painting services with minimal disturbance to your routine. We are experts in all interior and exterior painting and decorating services, and we are familiar with the various distinct finishes and systems available on the market.",
      image: Interior_Painting,
      servicelinktitle: "Read More",
    },
    {
      _id: 7,
      name: "Laminated Flooring",
      slug: "laminated-flooring",
      description:
        "Modern laminate flooring can be as beautiful as solid or engineered wood flooring, and is often more cost effective, allowing you to make your budget stretch further. So many different looks are available these days: wide planks, small planks, modern and traditional finishes – there really is something for everyone. With our modern fitting techniques, it is guaranteed to go down and stay down.",
      image: Laminated_Flooring,
      servicelinktitle: "Read More",
    },
    {
      _id: 8,
      name: "Local Builders",
      slug: "local-builders",
      description:
        "We are a reliable building, maintenance and refurbishment company serving both private and business customers in London. We can build a house extension, convert your loft or basement, renovate your house and much more. Whatever project you have in mind, give us a call and see how we can make it a reality.",
      image: Local_Builders,
      servicelinktitle: "Read More",
    },
    {
      _id: 9,
      name: "London Underground Refurbishments",
      slug: "london-underground-refurbishments",
      description:
        "At Ashmore Decorators we believe passionately in renovation and repair, and we are proud to have been part of the team responsible for painting, rendering, and repairing the underground stations of London. With our extensive expertise in all sorts of painting and decorating services we have been proud to take part in this worthwhile and ongoing project.",
      image: London_Underground_Refurbishments,
      servicelinktitle: "Read More",
    },
    {
      _id: 10,
      name: "Mastic Services",
      slug: "mastic-services",
      description:
        "We offer comprehensive and competitively priced sealant application services in a professional, friendly and timely manner. We use only the very best sealant products and you can always rely on us to deliver a first class service where the customer comes first. If you are looking for a specialist in sealant application services, choose Ashmore Decorators.",
      image: Mastic_Services,
      servicelinktitle: "Read More",
    },
    {
      _id: 11,
      name: "Painting Contractors",
      slug: "painting-contractors",
      description:
        "As painting contractors, we are fully qualified to take on any painting or decorating job in a residential or commercial setting. We take immense pride in the quality of our service and, as our client’s mention, we constantly deliver top quality results. We always utilise the very best painting equipment and materials to assure a perfect finish that you are fully satisfied with.",
      image: Painting_Contractors,
      servicelinktitle: "Read More",
    },
    {
      _id: 12,
      name: "Plastering Services",
      slug: "plastering-services",
      description:
        "As an experienced and reliable London plastering firm, we undertake all aspects of plastering work in London for both domestic and commercial customers. We offer quality work, including boarding, skimming, re-skimming over existing plaster, removing or re-covering artex, dry-lining, ceiling re-boarding, rendering and small patch up work.",
      image: Plastering_Services,
      servicelinktitle: "Read More",
    },
    {
      _id: 13,
      name: "Residential Decorating",
      slug: "residential-decorating",
      description:
        "Ashmore Decorators has a team of professional painters and decorators. We provide services ranging from patching up the decoration of your home where damage has occurred to extensive refurbishment and redecoration work.",
      image: Residential_Decorating,
      servicelinktitle: "Read More",
    },
    {
      _id: 14,
      name: "Sash Window Repair",
      slug: "sash-window-repair",
      description:
        "Is your sash window rotten, draughty, damaged, or hard to open and close? We are passionate about repairing windows, not just replacing them. We have brought back to life hundreds of sash windows many thought were beyond repair. We will assess the damage and the repair requirements, taking your budget into consideration, and offer an honest opinion and expert advice.",

      image: Sash_Window_Repair,
      servicelinktitle: "Read More",
    },
    {
      _id: 15,
      name: "Sash Window Replacement",
      slug: "sash-window-replacement",
      description:
        "Ashmore Decorators can provide replacement sash windows. We will send out our highly skilled team to assess the job and provide a quote that is cost effective without compromising on quality. So, whether you want one sash window replacement or many, give us a call today.",
      image: Sash_Window_Replacement,
      servicelinktitle: "Read More",
    },
    {
      _id: 16,
      name: "Sash Window Restoration",
      slug: "sash-window-restoration",
      description:
        "Our sash window restoration services utilise a special repair system that allows the original character of the window to be maintained. Combined with curative and preventative maintenance and repair methods, we can restore your timber windows to their former glory.",

      image: Sash_Window_Restoration,
      servicelinktitle: "Read More",
    },
    {
      _id: 17,
      name: "Skimming Specialists",
      slug: "skimming-specialists",
      description:
        "When you hire us you can rest assured that I am fully qualified, experienced and all of my plastering services are guaranteed. Pride is taken in every job to ensure all work is carried out to the highest specification, but we still take the time to ensure the work causes minimal disruption and mess. If you’re looking for a reputable skimming specialist in London who offers quality workmanship in all aspects of skimming then please get in touch today for a quick chat.",

      image: Skimming_Specialists,
      servicelinktitle: "Read More",
    },
    {
      _id: 18,
      name: "Tiling Services",
      slug: "tiling-services",
      description:
        "Here at Ashmore Decorators we specialise in all aspects of tiling and are able to refresh your existing bathroom, kitchen or flooring. When you choose to undertake your tiling work, you are guaranteed to receive great service combined and quality work. Contact us today for any kind of tiling service and we will be happy to help.",
      image: Tiling_Services,
      servicelinktitle: "Read More",
    },
    {
      _id: 19,
      name: "Wallpapering Services",
      slug: "wallpapering-services",
      description:
        "Hanging new wallpaper is a great way to refresh your home or business and we can advise on types of wallpaper as well as simply hanging wallpaper if you’ve already chosen it but need a hand getting it on the walls.Due to our experience and high level of craftsmanship, a great deal of our work comes from recommendation and repeat business.",
      image: Wallpapering_Services,
      servicelinktitle: "Read More",
    },
  ];

  const viewServiceDetails = (slug) => {
    navigate(`/service/${slug}`);
  };
  return (
    <>
      <div className="relative mb-20">
        <div
          className={classNames(
            "relative z-[2] w-full h-[200px] lg:h-[300px] xl:h-[400px] overflow-hidden rounded-b-3xl mb-10",
            "after:content-[''] after:absolute after:w-full after:h-full after:bg-gradient-to-t after:from-black/80 after:bottom-0 after:left-0 after:z-[1]"
          )}
        >
          <Image
            src={Servicebg}
            alt={""}
            width={"100%"}
            height={"100%"}
            effect={"blur"}
            className={" object-cover object-center"}
          />
          <h4 className="absolute left-0 right-0 mx-auto text-center text-white text-lg lg:text-xl xl:text-3xl capitalize bottom-20 lg:bottom:20 xl:bottom-40 z-10 font-bold">
            Services
          </h4>
        </div>

        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto mt-14">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service) => (
              <div
                key={service.id}
                className="p-6 border rounded-lg group hover:shadow-[0px_0px_25px_rgba(0,0,0,0.10)] transition-all duration-500 hover:scale-105"
              >
                <div className="aspect-[1/1] overflow-hidden">
                  <Image
                    src={service.image}
                    alt={""}
                    className={
                      " object-cover group-hover:rounded-lg group-hover:scale-150 transition-all duration-300"
                    }
                  />
                </div>
                <h2 className="text-xl font-bold my-4">{service.name}</h2>
                <p className="text-gray-800 text-base font-normal line-clamp-2">
                  {service.description}
                </p>
                <div
                  className={
                    "bg-carnation-600 text-white cursor-pointer px-5 py-2 rounded-lg !mt-5 block group-hover:bg-black transition-all duration-200 w-32 text-center group-hover:shadow-xl"
                  }
                  onClick={() => viewServiceDetails(service?.slug)}
                >
                  {service.servicelinktitle}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
