import React from "react";
// import TestimonialReview from "../components/testimonialreview/TestimonialReview";
import Image from "../components/elements/Image";
import Aboutbg from "../assets/images/about-bg.jpg";
import Aboutimage from "../assets/images/aboutright.jpg";
import Aboutleftimage from "../assets/images/about-left.jpg";
import { classNames } from "../helpers/classNames";
import Review from "../components/review/Review";
import TestimonialBottomContext from "../components/review/TestimonialBottomContext";
import AllReviewList from "../components/review/AllReviewList";
import Button from "../components/form/Button";

const About = () => {
  const reviews = {
    title: "Testimonials",
    subtitle:
      "Read our reviews showcasing our customer's experience with our company, outlining our commitment to service and a happy customer. We are dedicated to customer feedback and reviews to listen to our customers and provide the best service possible.We appreciate our customers taking the time to share their experiences and look forward to working with you.",
    subdata: [
      {
        _id: 1,
        icon: "fa-solid fa-star",
        rating: 5,
        name: "John Doe",
        meta: "Lorem ipsum",
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
      },
      {
        _id: 2,
        icon: "fa-solid fa-star",
        rating: 5,
        name: "John Doe",
        meta: "Lorem ipsum",
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
      },
      {
        _id: 3,
        icon: "fa-solid fa-star",
        rating: 5,
        name: "John Doe",
        meta: "Lorem ipsum",
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
      },
      {
        _id: 4,
        icon: "fa-solid fa-star",
        rating: 5,
        name: "John Doe",
        meta: "Lorem ipsum",
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
      },
      {
        _id: 5,
        icon: "fa-solid fa-star",
        name: "John Doe",
        meta: "Lorem ipsum",
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
      },
      {
        _id: 6,
        icon: "fa-solid fa-star",
        rating: 5,
        name: "John Doe",
        meta: "Lorem ipsum",
        description:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that",
      },
    ],
  };

  const aboutList = [
    {
      _id: 1,
      number: "1",
      title: "Our London-based professionals",
      description:
        "We only hire staff members who are highly skilled and have proven experience. We expect all team members to be well mannered and friendly with an adaptable, positive attitude to client satisfaction. At Ashmore Decorators, we always take immense pride in our exceptional workmanship, Health and safety are paramount, and is considered before, during and after the work is finished. We value customer satisfaction highly, and all works have a client satisfaction sheet to sign on completion to ensure we have exceeded your expectations.",
    },
    {
      _id: 2,
      number: "2",
      title: "Building up client relationships",
      description:
        "We are happy to offer advice to all our clients to aid you in achieving your design. We respect our environment and wherever possible seek to minimise the environmental impact of our work.",
    },
    {
      _id: 3,
      number: "3",
      title: "Rely upon us",
      description:
        "Whatever your project may be, our trained and experienced painters and decorators take a high level of personal responsibility. We work to determine the requirements of every client, working safely and tidily.",
    },
  ];

  return (
    <>
      <div className="relative">
        <div
          className={classNames(
            "relative z-[2] w-full h-[200px] lg:h-[300px] xl:h-[400px] overflow-hidden rounded-b-3xl mb-10",
            "after:content-[''] after:absolute after:w-full after:h-full after:bg-gradient-to-t after:from-black/80 after:bottom-0 after:left-0 after:z-[1]"
          )}
        >
          <Image
            src={Aboutbg}
            alt={""}
            width={"100%"}
            height={"100%"}
            effect={"blur"}
            className={" object-cover"}
          />
          <h4 className="absolute left-0 right-0 mx-auto text-center text-white text-lg lg:text-xl xl:text-3xl capitalize bottom-10 lg:bottom:20 xl:bottom-40 z-10 font-bold">
            About Us
          </h4>
        </div>
        <div className="relative abt !py-[50px] xl:!py-[100px]">
          <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
            <div className="grid grid-cols-12 gap-5 items-center">
              <div className="col-span-12">
                <span className="uppercase text-carnation-700 text-base font-bold">
                  Welcome to ashmore decorators
                </span>
                <h2 className="text-4xl font-normal mt-4 mb-5">
                  A LITTLE OUR STORY, About Ashmore Decorators
                </h2>
              </div>
              <div className="col-span-12 xl:col-span-6">
                <div className="text-left text-lg space-y-5">
                  For reliable painting and decorating services in London, you
                  can trust the team at Ashmore Decorators. We have the skills
                  and experience to get the job done.{" "}
                  <strong className="w-full block pt-5">
                    Why Ashmore Decorators?
                  </strong>{" "}
                  <p>
                    When you need a company that offers decorating services of
                    the highest standard, then get in touch with Ashmore
                    Decorators.
                  </p>{" "}
                  <p>
                    Our services cover residential and commercial properties in
                    and around the London area.Whatever your requirements are,
                    we aim to offer complete satisfaction at every stage of the
                    project.
                  </p>{" "}
                  <p>
                    All of our painters and decorators are qualified and highly
                    experienced professionals with the competence to complete
                    your work on schedule and to an excellent standard.
                  </p>
                </div>
              </div>
              <div className="col-span-12 xl:col-span-6">
                <div className="rounded-lg overflow-hidden shadow-xl">
                  <Image
                    src={Aboutimage}
                    alt={""}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-cover"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="grid grid-cols-1 xl:grid-cols-2 items-center">
            <div className="w-full h-full overflow-hidden hidden xl:block">
              <Image
                src={Aboutleftimage}
                alt={""}
                width={"100%"}
                height={"100%"}
                effect={"blur"}
                className={"object-cover h-full"}
              />
            </div>
            <div className="w-full h-full overflow-hidden p-10 leftdiv absolute xl:relative top-0">
              <div className="max-w-4xl">
                <div className="text-3xl font-extrabold capitalize text-white mb-12">
                  What Makes Us Unique?
                </div>
                <ul>
                  {aboutList.map((item) => (
                    <li
                      key={item.number}
                      className="grid grid-cols-12 gap-2 space-x-3 items-center mb-16"
                    >
                      <div className="col-span-2">
                        <span className="text-center text-white font-semibold text-4xl border border-yellow-600 p-7 block">
                          {item.number}
                        </span>
                      </div>
                      <div className="col-span-10">
                        <h2 className="text-white text-2xl font-medium capitalize !mb-3 underline">
                          {item.title}
                        </h2>
                        <p className="text-base text-slate-300">
                          {item.description}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="relative px-6 py-5 expert">
          <div className="bg-carnation-900/90 px-5 py-16 rounded-lg">
            <div className="relative text-white font-bold text-2xl text-center pb-3 mb-8 uppercase after:absolute after:border-b-2 after:border-white after:bottom-0 after:left-0 after:w-28 after:h-1 after:right-0 after:mx-auto">
              expert in decoration
            </div>
            <div className="text-white text-3xl capitalize w-[90%] mx-auto mb-5 mt-10 font-bold text-center">
              FOR EXPERT PAINTING AND DECORATIN IN LONDON
            </div>
            <div className="text-white text-base capitalize w-[90%] mx-auto mb-8 text-center">
              CALL US TODAY 02071694208 OR 07868729221.
            </div>
            <div className="flex justify-center">
              <Button
                buttonLabel={"Call Us Today"}
                buttonLabelClasses={"!text-sm"}
                buttonClasses={
                  "!px-4 !py-6 hover:!bg-black transition-all duration-300 !rounded-md !text-sm !bg-black"
                }
                buttonIcon={"fa-solid fa-phone-volume"}
                buttonIconPosition={"left"}
              />
            </div>
          </div>
        </div>
        <Review></Review>
        <TestimonialBottomContext />
        <AllReviewList></AllReviewList>
      </div>
    </>
  );
};

export default About;
