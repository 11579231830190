import React, { useEffect, useRef } from "react";
import { register } from "swiper/element/bundle";
import Button from "../form/Button";
import SingleStory from "./SingleStory";
register();

const TestimonialReview = ({ data }) => {
  const swiperElRef = useRef(null);

  useEffect(() => {
    const swiperEl = document.querySelector("swiper-container.stories");
    const swiperParams = {
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        0: {
          slidesPerView: 1.2,
          spaceBetween: 20,
          centeredSlides: true,
          loop: true,
        },
        640: {
          slidesPerView: 2,
          spaceBetween: 20,
          centeredSlides: true,
          loop: true,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
          centeredSlides: false,
          loop: false,
        },
        1024: {
          slidesPerView: 2.5,
          spaceBetween: 20,
          centeredSlides: false,
          loop: false,
        },
        1280: {
          slidesPerView: 3.5,
          spaceBetween: 20,
          centeredSlides: false,
          loop: false,
        },
      },
      on: {
        init() {},
      },
    };
    Object.assign(swiperEl, swiperParams);
    swiperEl.initialize();
  }, []);

  return (
    <>
      <section className="relative overflow-hidden w-full bg-carnation-600 lg:bg-gray-100 py-10 lg:py-0">
        <div className="relative w-full px-4 xl:pl-4 mx-auto">
          <div className="relative flex flex-col lg:flex-row items-center gap-10 z-[1]">
            <div className="w-full lg:w-1/3 relative z-[1] text-center lg:text-left">
              <div className="hidden lg:block absolute top-1/2 -translate-y-1/2 right-0 w-[1000%] h-[1000%] bg-gray-100 z-[-1]"></div>
              <h2 className="font-Lexend text-4xl font-extrabold text-white lg:text-black mb-3">
                {data.title}
              </h2>
              <div className="font-Lexend text-sm sm:text-sm text-white lg:text-slate-500">
                {data.subtitle}
              </div>
            </div>
            {data?.subdata?.length > 0 && (
              <div className="relative w-full lg:w-2/3 z-0 lg:py-24">
                <div className="hidden lg:block absolute top-0 left-40 w-[1000%] h-[1000%] z-[-1] testibg"></div>
                <div className="relative">
                  <swiper-container
                    ref={swiperElRef}
                    class="overflow-visible stories"
                    init="false"
                  >
                    {data?.subdata?.map((item) => (
                      <swiper-slide key={item._id} className="!h-auto flex">
                        <SingleStory data={item} />
                      </swiper-slide>
                    ))}
                  </swiper-container>
                  <div className="flex relative justify-center lg:justify-start lg:left-48 mt-8 !gap-x-2">
                    <Button
                      buttonClasses={
                        '!relative !top-auto !bottom-auto !left-auto !right-auto !m-0 !w-10 bg-white !text-slate-800 !h-8 !pl-5 !pr-0 !rounded-lg swiper-button-next after:content-[""] !gap-0 !text-center hover:!bg-carnation-500 hover:!text-white'
                      }
                      buttonType={"button"}
                      buttonLabel={""}
                      buttonLabelClasses={"hover:!text-white"}
                      buttonIcon={"fa-regular fa-arrow-left-long"}
                      buttonIconPosition={"left"}
                    />
                    <Button
                      buttonClasses={
                        '!relative !top-auto !bottom-auto !left-auto !right-auto !m-0 !w-10 bg-white !text-slate-800 !h-8 !pl-5 !pr-0 !rounded-lg swiper-button-next after:content-[""] !gap-0 !text-center hover:!bg-carnation-500 hover:!text-white'
                      }
                      buttonType={"button"}
                      buttonLabel={""}
                      buttonLabelClasses={""}
                      buttonIcon={"fa-regular fa-arrow-right-long"}
                      buttonIconPosition={"left"}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default TestimonialReview;
