import React, { useEffect, useState } from "react";
import { classNames } from "../helpers/classNames";
import Image from "../components/elements/Image";
import Blogbg from ".././../src/assets/images/blog.jpg";
import { Link } from "react-router-dom/dist/umd/react-router-dom.development";
import { useNavigate, useParams } from "react-router-dom";
import blog1 from ".././../src/assets/images/blog1.webp";
import blog2 from ".././../src/assets/images/blog2.webp";
import blog3 from ".././../src/assets/images/blog3.webp";

const BlogDetails = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);

  const blogData = [
    {
      _id: 1,
      image: blog1,
      month: "January",
      title: "Money back guarantee if not absolutely delighted!",
      date: "2023-10-28",
      author: "Author 1",
      content:
        "If you are familiar with advertisements for novelty domestic gadgets (See ‘B List’ TV channels around 3:15am) you ",
      postlinktitle: "Read More",
      url: "/post/money-back-guarantee-if-not-absolutely-delighted",
      slug: "money-back-guarantee-if-not-absolutely-delighted",
    },
    {
      id: 2,
      image: blog2,
      month: "October",
      title: "Oh no please NO!!!……not The Canned Presentation?!",
      slug: "oh-no-please-nonot-the-canned-presentation",
      date: "2023-10-28",
      author: "Author 2",
      content:
        "When the Spanish Inquisition (as reimagined by Monty Python’s Flying Circus) wanted to torture their victims into confessing",
      postlinktitle: "Read More",
      url: "/post/oh-no-please-nonot-the-canned-presentation",
    },
    {
      id: 3,
      image: blog3,
      month: "January",
      title: "What’s a ‘Strategy’? ….it’s a…you- know…a ‘thingy’ !",
      date: "2023-10-28",
      author: "Author 3",
      slug: "whats-a-strategy-its-ayou-knowa-thingy",
      content:
        "The above answer to my question, “Could you define the word Strategy?” came from one of Asia’s top",
      postlinktitle: "Read More",
      url: "/post/whats-a-strategy-its-ayou-knowa-thingy",
    },
  ];
  useEffect(() => {
    if (slug) {
      const foundService = blogData.find((service) => service.slug === slug);
      if (foundService) {
        setBlog(foundService);
      }
    }
  }, [slug]);

  const formatDate = (dateString) => {
    const options = { month: "long", day: "numeric", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const serviceList = [
    {
      _id: 1,
      name: "Commercial Painting Contractors",
      slug: "commercial-painting-contractors",
    },
    { _id: 2, name: "Coving Services", slug: "coving-services" },
    { _id: 3, name: "Decorating Services", slug: "decorating-services" },
    { _id: 4, name: "Exterior Painting", slug: "exterior-painting" },
    { _id: 5, name: "Exterior Rendering", slug: "exterior-rendering" },
    { _id: 6, name: "Interior Painting", slug: "interior-painting" },
    { _id: 7, name: "Laminated Flooring", slug: "laminated-flooring" },
    { _id: 8, name: "Local Builders", slug: "local-builders" },
    {
      _id: 9,
      name: "London Underground Refurbishments",
      slug: "london-underground-refurbishments",
    },
    { _id: 10, name: "Mastic Services", slug: "mastic-services" },
    { _id: 11, name: "Painting Contractors", slug: "painting-contractors" },
    { _id: 12, name: "Plastering Services", slug: "plastering-services" },
    { _id: 13, name: "Residential Decorating", slug: "residential-decorating" },
    { _id: 14, name: "Sash Window Repair", slug: "sash-window-repair" },
    {
      _id: 15,
      name: "Sash Window Replacement",
      slug: "sash-window-replacement",
    },
    {
      _id: 16,
      name: "Sash Window Restoration",
      slug: "sash-window-restoration",
    },
    { _id: 17, name: "Skimming Specialists", slug: "skimming-specialists" },
    { _id: 18, name: "Tiling Services", slug: "tiling-services" },
    { _id: 19, name: "Wallpapering Services", slug: "wallpapering-services" },
  ];

  const viewServiceDetails = (data) => {
    navigate(`/service/${data}`);
    // console.log(data, "dataaa");
  };

  const filteredBlogs = blogData.filter((blog) => blog.slug !== slug);

  if (filteredBlogs.length === 0) {
    return <div>Blog not found</div>;
  }

  return (
    <>
      <div className="relative">
        <div
          className={classNames(
            "relative z-[2] w-full h-[200px] lg:h-[300px] xl:h-[400px] overflow-hidden rounded-b-3xl mb-10",
            "after:content-[''] after:absolute after:w-full after:h-full after:bg-gradient-to-t after:from-black/80 after:bottom-0 after:left-0 after:z-[1]"
          )}
        >
          <Image
            src={Blogbg}
            alt={""}
            width={"100%"}
            height={"100%"}
            effect={"blur"}
            className={"object-cover object-bottom"}
          />
          <h4 className="absolute left-0 right-0 mx-auto text-center text-white text-lg lg:text-xl xl:text-3xl capitalize bottom-20 lg:bottom:20 xl:bottom-40 z-10 font-bold">
            blogs details
          </h4>
        </div>

        <div className="relative my-20">
          <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
            <div className="grid grid-cols-12 gap-5 xl:gap-10 3xl:gap-16">
              <div className="relative lg:space-y-8 col-span-12 xl:col-span-8 lg:gap-0 lg:block">
                <div className="relative bg-white rounded-3xl">
                  <div className="relative z-0">
                    <div
                      className={classNames(
                        "relative z-[2] w-full h-80 sm:h-[420px] xl:h-[480px] 2xl:h-[600px] overflow-hidden rounded-3xl ",
                        "after:content-[''] after:absolute after:w-full after:h-40 after:bg-gradient-to-t after:from-black/60 after:bottom-0 after:left-0 after:z-[1]"
                      )}
                    >
                      <Image
                        src={blog?.image}
                        alt={""}
                        width={"100%"}
                        height={"100%"}
                        effect={"blur"}
                        classes={"object-cover"}
                      />
                    </div>
                  </div>
                  <div className="py-8 px-6 !-mt-14 bg-white relative z-[2] w-[95%] mx-auto rounded-lg shadow-[0px_0px_25px_rgba(0,0,0,0.10)] mb-14">
                    <p className="bg-carnation-600 px-3 py-3 text-center text-white text-base rounded-lg -mt-14 w-40 relative z-[2] left-3">
                      {formatDate(blog?.date)}
                    </p>
                    <h4 className="text-black font-extrabold text-2xl mt-8 mb-3 ml-4 relative after:absolute after:border-b after:border-carnation-700 after:bottom-0 after:left-0 after:w-32 after:h-5 block pb-3">
                      {blog?.title}
                    </h4>
                    {/* <span className="text-slate-500 font-medium text-lg ml-4 relative after:absolute after:border-b after:border-carnation-700 after:bottom-0 after:left-0 after:w-32 after:h-5 block pb-3">
                      By Andrew Smith Architecture, Interior
                    </span> */}
                  </div>
                  <div className="text-base text-slate-600">
                    {blog?.content}
                  </div>
                  {/* <div className="text-base text-slate-600 mt-8">
                    Convenire gubergren ex vix. Habeo facete pri ei. Putent maluisset intellegam vixte vim prompta facilisi interesset te. An denique praesent proin torquatos. Lorem ipsum dolor sit
                    amet consecteta adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris. Nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.
                  </div> */}
                  <h3 className="text-black font-bold text-2xl capitalize mt-8 mb-8 relative after:absolute after:border-b after:border-carnation-700 after:bottom-0 after:left-0 after:w-32 after:h-5 pb-2">
                    Recommended Posts
                  </h3>
                  <div className="grid grid-cols-1 xl:grid-cols-2 gap-5 mt-5 mb-20">
                    {filteredBlogs.map((blog) => (
                      <div
                        key={blog._id}
                        className="rounded-lg border-2 border-slate-300"
                      >
                        <div className="overflow-hidden">
                          <Image
                            src={blog.image}
                            alt={blog.title}
                            className="object-cover"
                          />
                        </div>
                        <a
                          href={blog.url}
                          className="text-carnation-900 text-lg font-bold py-8 px-3 block"
                        >
                          {blog.title}
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="relative col-span-12 xl:col-span-4">
                <div className="text-black font-bold text-2xl capitalize mt-8 mb-8 relative after:absolute after:border-b after:border-carnation-700 after:bottom-0 after:left-0 after:w-32 after:h-5 pb-2">
                  Categories Services
                </div>
                <ul className="space-y-2">
                  {serviceList.map((item, index) => (
                    <li key={index} className="w-full group">
                      <div
                        onClick={() => viewServiceDetails(item?.slug)}
                        className="text-white text-sm xl:text-lg bg-black/80 px-4 py-4 relative block hover:bg-black/100 transition-all duration-300 group-hover:text-carnation-500"
                      >
                        <div className="absolute right-0 text-white bg-carnation-600 p-4 -mt-4">
                          <i class="fa-solid fa-arrow-right"></i>
                        </div>
                        {item.name}
                      </div>
                    </li>
                  ))}
                </ul>

                {/* <div className="text-black font-bold text-2xl capitalize mt-8 mb-8 relative after:absolute after:border-b after:border-carnation-700 after:bottom-0 after:left-0 after:w-32 after:h-5 pb-2">
                  Recent Posts
                </div>
                <ul className="w-full bg-slate-50 rounded-lg border-slate-100 border block">
                  <li className="">
                    <Link href="/" className={"w-full grid grid-cols-12 gap-5 items-center p-4"}>
                      <div className="overflow-hidden rounded-lg border border-carnation-700 col-span-3">
                        <Image src={Blogdetail1} alt={""} className={" object-cover"} />
                      </div>
                      <div className="w-full col-span-7">
                        <h4 className="font-bold text-black text-lg mb-3 line-clamp-2">Money back guarantee if not absolutely delighted!</h4>
                        <span className="font-normal text-slate-500 text-sm uppercase leading-3">Posted on October 28, 2023</span>
                      </div>
                    </Link>
                  </li>
                  <li className="">
                    <Link href="/" className={"w-full grid grid-cols-12 gap-5 items-center p-4"}>
                      <div className="overflow-hidden rounded-lg border border-carnation-700 col-span-3">
                        <Image src={Postthumb1} alt={""} className={" object-cover"} />
                      </div>
                      <div className="w-full col-span-7">
                        <h4 className="font-bold text-black text-lg mb-3 line-clamp-2">Money back guarantee if not absolutely delighted!</h4>
                        <span className="font-normal text-slate-500 text-sm uppercase leading-3">Posted on October 28, 2023</span>
                      </div>
                    </Link>
                  </li>
                  <li className="">
                    <Link href="/" className={"w-full grid grid-cols-12 gap-5 items-center p-4"}>
                      <div className="overflow-hidden rounded-lg border border-carnation-700 col-span-3">
                        <Image src={Postthumb2} alt={""} className={" object-cover"} />
                      </div>
                      <div className="w-full col-span-7">
                        <h4 className="font-bold text-black text-lg mb-3 line-clamp-2">Money back guarantee if not absolutely delighted!</h4>
                        <span className="font-normal text-slate-500 text-sm uppercase leading-3">Posted on October 28, 2023</span>
                      </div>
                    </Link>
                  </li>
                  <li className="">
                    <Link href="/" className={"w-full grid grid-cols-12 gap-5 items-center p-4"}>
                      <div className="overflow-hidden rounded-lg border border-carnation-700 col-span-3">
                        <Image src={Blogdetail1} alt={""} className={" object-cover"} />
                      </div>
                      <div className="w-full col-span-7">
                        <h4 className="font-bold text-black text-lg mb-3 line-clamp-2">Money back guarantee if not absolutely delighted!</h4>
                        <span className="font-normal text-slate-500 text-sm uppercase leading-3">Posted on October 28, 2023</span>
                      </div>
                    </Link>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
