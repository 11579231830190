import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Image from "../elements/Image";
import Button from "../form/Button";
import DesignButton from "../extra/DesignButton";

const SingleBlog = ({ data }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="">
        <div
          className={
            "w-full flex flex-col bg-white rounded-4xl rounded-tl-none overflow-hidden transition-all duration-200 hover:shadow-lg"
          }
        >
          <div
            className={
              data?.smallImage
                ? "w-full data.smallImage?'70%': overflow-hidden rounded-4xl rounded-tl-none"
                : "aspect-[9/8] overflow-hidden rounded-4xl rounded-tl-none"
            }
          >
            <Link to={`/post/${data?.slug}`}>
              <></>
              <Image
                src={data && data?.image}
                alt={""}
                width={"100%"}
                height={"100%"}
                effect={"blur"}
                className={" object-cover"}
              />
            </Link>
          </div>
          <div className="py-6 px-4">
            <h1 className="font-Lexend text-org-theme text-sm lg:text-base xl:text-lg 2xl:text-xl 3xl:text-2xl font-medium leading-tight ">
              {data?.name}
            </h1>
            <div
              className="text-xs xl:text-sm text-slate-600 my-4 text-ellips-desc line-clamp-2"
              dangerouslySetInnerHTML={{ __html: data?.description }}
            />
            <div className="relative">
              <div className="relative flex">
                <div className="text-slate-900">{data.date}</div>
              </div>
              <div className="">
                <div></div>
              </div>
            </div>

            <div className="flex items-center justify-between mt-auto">
              <DesignButton
                buttonClasses={"!px-8 !h-10 !ml-auto !bg-carnation-600"}
                buttonHasLink={false}
                buttonLabel={"View"}
                buttonLabelClasses={"text-sm"}
                buttonEffect={"filled"}
                buttonFunction={() => navigate(`/post/${data?.slug}`)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleBlog;
