import React from "react";
import Google from ".././../assets/images/googlev2.png";
import checktrade from ".././../assets/images/checkatrade.png";
import facebook from ".././../assets/images/facebookv3.png";
import Yell from ".././../assets/images/yell-review-logo.png";
import Image from "../elements/Image";
import Rating from "./Rating"; // Adjust import if needed

const TestimonialBottomContext = () => {
  const reviewWriting = [
    {
      _id: 1,
      iconimage: Google,
      title: "Write a Review",
      url: "https://www.google.com/search?hl=en-IN&gl=in&q=Ashmore+Decorators+Ltd,+74+Ashmore+Rd,+London+W9+3DG,+United+Kingdom&ludocid=10323112801943537386&lsig=AB86z5UGX7dx1tSlM_QMJIAIW8hp#lrd=0x48761010f83be47b:0x8f4310663021c6ea,3",
    },
    {
      _id: 2,
      iconimage: checktrade,
      title: "Write a Review",
      url: "https://www.checkatrade.com/trades/AshmoreDecorators",
    },
    {
      _id: 3,
      iconimage: facebook,
      title: "Write a Review",
      url: "https://www.facebook.com/AshmoreDecorators/reviews/?ref=page_internal",
    },
    {
      _id: 4,
      iconimage: Yell,
      title: "Write a Review",
      url: "https://www.yell.com/biz/ashmore-decorators-ltd-london-8887847/#reviews",
    },
  ];

  const totalReviews = 29;

  const ratings = [
    { stars: 5, count: 28 },
    { stars: 4, count: 1 },
    { stars: 3, count: 0 },
    { stars: 2, count: 0 },
    { stars: 1, count: 0 },
  ];

  return (
    <>
      <div className="relative">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div className="relative bg-slate-900 px-8 py-20 space-y-5 ">
            <div className="relative text-2xl font-bold text-center text-white pb-3 after:absolute after:border-b-2 after:border-carnation-500 after:bottom-0 after:left-0 after:w-28 after:h-1 after:right-0 after:mx-auto">
              Leave Us a Review
            </div>
            <div className="text-base font-normal text-slate-200">
              We've found that customer reviews are very helpful in keeping our
              business thriving. We would truly appreciate a review from you!
            </div>
            <div className="text-base font-normal text-slate-200">
              Visit your preferred site to leave a review or comment.
            </div>
            <ul className="grid grid-cols-2 xl:flex justify-center items-center border-t border-slate-500 pt-10 !mt-10">
              {reviewWriting.map((item, index) => (
                <li
                  key={index}
                  className="space-y-10 px-1 lg:px-5 xl:pl-2 2xl:pl-5"
                >
                  <div className="w-24 mx-auto">
                    <Image
                      src={item.iconimage}
                      alt={""}
                      className="object-cover"
                    />
                  </div>
                  <a
                    href={item.url}
                    target="_blank"
                    rel=""
                    className="bg-carnation-600 text-white cursor-pointer text-center px-3 xl:px-2 2xl:px-5 py-2 rounded-lg !my-5 xl:!mt-5 block"
                  >
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="relative justify-center bg-slate-200 px-8 py-20">
            <div className="grid grid-cols-12 w-full space-x-10">
              <div className="col-span-3 xl:col-span-2 2xl:col-span-1">
                <div className="font-black text-6xl">4.9</div>
              </div>
              <div className="col-span-9 xl:col-span-2 2xl:col-span-1">
                <div className="capitalize w-full xl:w-36 text-black font-normal text-lg">
                  out of 5 stars
                </div>
                <div className="relative col-span-7 xl:col-span-8 2xl:col-span-10">
                  <ul className="flex">
                    <li className="text-lg">
                      <i className="fa-solid fa-star-sharp text-yellow-500"></i>
                    </li>
                    <li className="text-lg">
                      <i className="fa-solid fa-star-sharp text-yellow-500"></i>
                    </li>
                    <li className="text-lg">
                      <i className="fa-solid fa-star-sharp text-yellow-500"></i>
                    </li>
                    <li className="text-lg">
                      <i className="fa-solid fa-star-sharp text-yellow-500"></i>
                    </li>
                    <li className="text-lg">
                      <i className="fa-solid fa-star-sharp-half text-yellow-500"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="grid w-full mt-3">
              <p className="text-black text-lg w-full">
                Overall rating of 29 1st-party reviews
              </p>
              {ratings.map((rating) => (
                <div key={rating.stars} className="flex items-center my-1">
                  <span className="text-sm font-semibold">
                    {rating.stars} star
                  </span>
                  <div className="flex-1 mx-2 bg-gray-300 h-4 rounded-lg overflow-hidden">
                    <div
                      className="bg-carnation-600 h-4"
                      style={{
                        width: `${(rating.count / totalReviews) * 100}%`,
                      }}
                    ></div>
                  </div>
                  <span className="text-sm font-semibold">
                    {rating.count} People
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialBottomContext;
