import React from "react";
import { classNames } from "../helpers/classNames";
import Image from "../components/elements/Image";
import Blogbg from ".././../src/assets/images/blog.jpg";
import Button from "../components/form/Button";
import blog1 from ".././../src/assets/images/blog1.webp";
import blog2 from ".././../src/assets/images/blog2.webp";
import blog3 from ".././../src/assets/images/blog3.webp";
import { useNavigate } from "react-router-dom";

const BlogList = () => {
  const navigate = useNavigate();
  const formatDate = (dateString) => {
    const options = { month: "long", day: "numeric", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const blogData = [
    {
      _id: 1,
      image: blog1,
      month: "January",
      title: "Money back guarantee if not absolutely delighted!",
      date: "2023-10-28",
      author: "Author 1",
      content:
        "If you are familiar with advertisements for novelty domestic gadgets (See ‘B List’ TV channels around 3:15am) you ",
      postlinktitle: "Read More",
      slug: "money-back-guarantee-if-not-absolutely-delighted",
    },
    {
      id: 2,
      image: blog2,
      month: "October",
      title: "Oh no please NO!!!……not The Canned Presentation?!",
      slug: "oh-no-please-nonot-the-canned-presentation",
      date: "2023-10-28",
      author: "Author 2",
      content:
        "When the Spanish Inquisition (as reimagined by Monty Python’s Flying Circus) wanted to torture their victims into confessing",
      postlinktitle: "Read More",
    },
    {
      id: 3,
      image: blog3,
      month: "January",
      title: "What’s a ‘Strategy’? ….it’s a…you- know…a ‘thingy’ !",
      date: "2023-10-28",
      author: "Author 3",
      slug: "whats-a-strategy-its-ayou-knowa-thingy",
      content:
        "The above answer to my question, “Could you define the word Strategy?” came from one of Asia’s top",
      postlinktitle: "Read More",
    },
  ];

  const viewBlog = (data) => {
    navigate(`/post/${data}`);
  };

  return (
    <>
      <div className="relative">
        <div
          className={classNames(
            "relative z-[2] w-full h-[200px] lg:h-[300px] xl:h-[400px] overflow-hidden rounded-b-3xl mb-10",
            "after:content-[''] after:absolute after:w-full after:h-full after:bg-gradient-to-t after:from-black/80 after:bottom-0 after:left-0 after:z-[1]"
          )}
        >
          <Image
            src={Blogbg}
            alt={""}
            width={"100%"}
            height={"100%"}
            effect={"blur"}
            className={"object-cover object-bottom"}
          />
          <h4 className="absolute left-0 right-0 mx-auto text-center text-white text-lg lg:text-xl xl:text-3xl capitalize bottom-20 lg:bottom:20 xl:bottom-40 z-10 font-bold">
             Blogs
          </h4>
        </div>

        <div className="relative my-20">
          <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8">
              {blogData.map((post) => (
                <div
                  key={post.id}
                  className="bg-white border-t-4 border-carnation-400 shadow-md rounded p-6 group"
                >
                  <div className="aspect-[9/8] overflow-hidden rounded-lg">
                    <Image
                      src={post.image}
                      alt={""}
                      className={
                        " object-cover group-hover:transform transition-all duration-500 group-hover:scale-125 group-hover:rotate-6"
                      }
                    />
                  </div>
                  <p className="bg-slate-700 px-3 py-3 text-center text-white text-base rounded-lg -mt-5 w-40 relative z-[2] left-3">
                    {formatDate(post.date)}
                  </p>
                  <h2 className="text-xl font-bold my-4">{post.title}</h2>
                  {/* <p className="text-gray-600 mb-5 font-semibold uppercase leading-3 text-sm">
                    By {post.author}
                  </p> */}
                  <p className="text-gray-800 text-base font-normal line-clamp-2">
                    {post.content}
                  </p>
                  {/* <div
                    onClick={() => viewBlog(post?.slug)}
                    className="bg-carnation-600 text-white cursor-pointer px-5 py-2 rounded-lg !mt-5 block group-hover:bg-black transition-all duration-200 w-32 text-center group-hover:shadow-xl"
                  >
                    {post.postlinktitle}
                  </div> */}
                  <div
                    className={
                      "bg-carnation-600 text-white cursor-pointer px-5 py-2 rounded-lg !mt-5 block group-hover:bg-black transition-all duration-200 w-32 text-center group-hover:shadow-xl"
                    }
                    onClick={() => viewBlog(post?.slug)}
                  >
                    {post.postlinktitle}
                  </div>
                </div>
              ))}
            </div>
            {/* <div className="flex justify-center">
              <Button
                buttonLabel={"Load more posts"}
                buttonLabelClasses={"!text-xl"}
                buttonClasses={
                  "!my-20 !px-4 !h-14 !py-4 !text-lg !rounded-lg !capitalize !font-bold !border !border-white !shadow-lg hover:!bg-black transition-all duration-300"
                }
                buttonIcon={"fa-sharp fa-solid fa-arrow-down"}
                buttonIconPosition={"left"}
              />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogList;
