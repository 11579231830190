import { apiRequest } from "../util/async/apiUtils";


export const addContact = async (params) => {
  try {
    const res = await apiRequest(
      `${params.id ? "put" : "post"}`,
      `${params.id ? `relation/edit/${params.id}` : "/ashmore.contact.accabanquet.com/"}`,
      {
        body: params,
      },
      "application/x-www-form-urlencoded"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

