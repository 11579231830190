import React, { useCallback, useEffect, useRef, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import HomeBanner from "../components/banner/HomeBanner";
import ServiceCard from "../components/serviceCard/ServiceCard";
import BlogCard from "../components/blogs/BlogCard";
import Review from "../components/review/Review";
import TestimonialBottomContext from "../components/review/TestimonialBottomContext";
import Button from "../components/form/Button";
import AllReviewList from "../components/review/AllReviewList";
import Image from "../components/elements/Image";
import Decoratorimg from ".././../src/assets/images/director-bg.jpg";
import Aboutimagefirst from ".././../src/assets/images/bottom_banner1.jpg";
import Aboutimagesecond from ".././../src/assets/images/bottom_banner2.jpg";
import Aboutimagethird from ".././../src/assets/images/bottom_banner3.jpg";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import blog1 from ".././../src/assets/images/blog1.webp";
import blog2 from ".././../src/assets/images/blog2.webp";
import blog3 from ".././../src/assets/images/blog3.webp";
import Commercial_Painting_Contractors from ".././../src/assets/images/commercial-painting-contractors.jpg";
import Coving_Services from ".././../src/assets/images/coving-services.jpg";
import Decorating_Services from ".././../src/assets/images/decorating-services.jpg";
import Exterior_Painting from ".././../src/assets/images/exterior-painting.webp";
import Exterior_Rendering from ".././../src/assets/images/exterior-rendering.jpg";
import Interior_Painting from ".././../src/assets/images/interior-painting.webp";
import Laminated_Flooring from ".././../src/assets/images/laminated-flooring.jpg";
import Local_Builders from ".././../src/assets/images/local-builders.jpg";
import London_Underground_Refurbishments from ".././../src/assets/images/london-underground-refurbishments.jpg";
import Mastic_Services from ".././../src/assets/images/mastic-services.jpg";
import Painting_Contractors from ".././../src/assets/images/painting-contractors.jpg";
import Plastering_Services from ".././../src/assets/images/plastering-services.jpg";
import Residential_Decorating from ".././../src/assets/images/residential-decorating.webp";
import Sash_Window_Repair from ".././../src/assets/images/sash-window-repair.jpg";
import Sash_Window_Replacement from ".././../src/assets/images/sash-window-replacement.jpg";
import Sash_Window_Restoration from ".././../src/assets/images/sash-window-restoration.jpg";
import Skimming_Specialists from ".././../src/assets/images/skimming-specialists.jpg";
import Tiling_Services from ".././../src/assets/images/tiling-services.jpg";
import Wallpapering_Services from ".././../src/assets/images/wallpapering-services.jpg";
import HBanner from ".././../src/assets/images/home-banner.jpg";
import footerlogo from "./../assets/images/footer_logo2.png";
import footerlogofirst from "./../assets/images/logo-1.png";
import prize from "./../assets/images/prize.jpg";
import Footer_Second_Logo from "./../assets/images/footer_Second_Circle_logo.png";


const Home = () => {
  setTitle("Ashmore Decorators| Home");
  const [loaded, setLoaded] = useState(true);
  const navigate = useNavigate();

  const bannerData = {
    image: HBanner,
    title:
      // "Many desktop <span class='text-carnation-500'>publishing</span> packages",
      "London's Premier Decorating Specialists",
    subtitle: "Donate to contribution",
    description:
      "Pride to have decorated Hollywood start Brian Cox. Offering unparalleled expertise and dedication to every project.",
    actionLink: "/service",
    actionLabel: "Connect With Our Experts Today",
    loaded: loaded,
  };

  const serviceData = {
    loaded: true,
    subdata: [
      {
        _id: 1,
        name: "Commercial Painting Contractors",
        description:
          "We offer a commercial and industrial painting service across London. Our painting services cover almost all forms of commercial properties including shops and retail outlets, offices, factories, hotels, petrol stations, bars, underground stations, and restaurants. Our expert team also offers domestic painting and decorating services.",
        image: Commercial_Painting_Contractors,
        slug: "commercial-painting-contractors",
      },
      {
        _id: 2,
        name: "Coving Services",
        description:
          "Ashmore Decorators’s qualified plasterers perform a range of plastering services including internal coving to make the most of your property. You can rely on our time-served tradesmen to carry out professional plastering, rendering, and coving services to the highest of standards. We pride ourselves on always finishing the work on time, leaving your property clean and tidy.",
        image: Coving_Services,
        slug: "coving-services",
      },
      {
        _id: 3,
        name: "Decorating Services",
        description:
          "From extensive makeovers to subtle changes, our decorators can achieve the desired result. We will turn your ideas into reality, sharing our advice along the way. We pay great attention to detail to every step of the process. You can expect high standards at every stage. Interior decorating service Ashmore Decorators’ interior decorating and finishing work is delivered by specialists with years of experience. Exterior decorating services When you want to attract more customers to your business, having a clean well-decorated premises will encourage people to step inside. Ashmore Decorators have many years’ experience in domestic, commercial and retail decoration and can make your building’s exterior look absolutely fabulous.",
        image: Decorating_Services,
        slug: "decorating-services",
      },
      {
        _id: 4,
        name: "Exterior Painting",
        description:
          "The exterior of your property is the first impression that visitors will form of you and your home or business, and as experienced painters, we are here to make certain that first impression is a good one! Building exteriors are subject to damage by weather as well as pollution and grime. Our professionals are experts in providing exterior painting services across London.",
        image: Exterior_Painting,
        slug: "exterior-painting",
      },
      {
        _id: 5,
        name: "Exterior Rendering",
        description:
          "Improve the look of your home instantly with external plastering and rendering, a process for evening out surfaces in preparation for you painting your property. Ashmore Decorators specialises in external plastering and rendering, giving your property protection against the elements all year round. Our qualified plasterers have the skills and experience to work with a wide choice of materials to give you a personal finish.",
        image: Exterior_Rendering,
        slug: "exterior-rendering",
      },
      {
        _id: 6,
        name: "Interior Painting",
        description:
          "Ashmore Decorators carries out an extensive range of quality and affordable painting services with minimal disturbance to your routine. We are experts in all interior and exterior painting and decorating services, and we are familiar with the various distinct finishes and systems available on the market.",
        image: Interior_Painting,
        slug: "interior-painting",
      },
      {
        _id: 7,
        name: "Laminated Flooring",
        description:
          "Modern laminate flooring can be as beautiful as solid or engineered wood flooring, and is often more cost effective, allowing you to make your budget stretch further. So many different looks are available these days: wide planks, small planks, modern and traditional finishes – there really is something for everyone. With our modern fitting techniques, it is guaranteed to go down and stay down.",
        image: Laminated_Flooring,
        slug: "laminated-flooring",
      },
      {
        _id: 8,
        name: "Local Builders",
        description:
          "We are a reliable building, maintenance and refurbishment company serving both private and business customers in London. We can build a house extension, convert your loft or basement, renovate your house and much more. Whatever project you have in mind, give us a call and see how we can make it a reality.",
        image: Local_Builders,
        slug: "local-builders",
      },
      {
        _id: 9,
        name: "London Underground Refurbishments",
        description:
          "At Ashmore Decorators we believe passionately in renovation and repair, and we are proud to have been part of the team responsible for painting, rendering, and repairing the underground stations of London. With our extensive expertise in all sorts of painting and decorating services we have been proud to take part in this worthwhile and ongoing project.",
        image: London_Underground_Refurbishments,
        slug: "london-underground-refurbishments",
      },
      {
        _id: 10,
        name: "Mastic Services",
        description:
          "We offer comprehensive and competitively priced sealant application services in a professional, friendly and timely manner. We use only the very best sealant products and you can always rely on us to deliver a first class service where the customer comes first. If you are having a new kitchen fitted, need your bathroom or wet room sealing, or need the windows and doors sealing in your new home, we are here to help.",
        image: Mastic_Services,
        slug: "mastic-services",
      },
      {
        _id: 11,
        name: "Painting Contractors",
        description:
          "As painting contractors, we are fully qualified to take on any painting or decorating job in a residential or commercial setting. We take immense pride in the quality of our service and, as our client’s mention, we constantly deliver top quality results. We always utilise the very best painting equipment and materials to assure a perfect finish that you are fully satisfied with.",
        image: Painting_Contractors,
        slug: "painting-contractors",
      },
      {
        _id: 12,
        name: "Plastering Services",
        description:
          "As an experienced and reliable London plastering firm, we undertake all aspects of plastering work in London for both domestic and commercial customers. We offer quality work, including boarding, skimming, re-skimming over existing plaster, removing or re-covering artex, dry-lining, ceiling re-boarding, rendering and small patch up work.",
        image: Plastering_Services,
        slug: "plastering-services",
      },
      {
        _id: 13,
        name: "Residential Decorating",
        description:
          "Ashmore Decorators has a team of professional painters and decorators. We provide services ranging from patching up the decoration of your home where damage has occurred to extensive refurbishment and redecoration work.",
        image: Residential_Decorating,
        slug: "residential-decorating",
      },
      {
        _id: 14,
        name: "Sash Window Repair",
        description:
          "Is your sash window rotten, draughty, damaged, or hard to open and close? We are passionate about repairing windows, not just replacing them. We have brought back to life hundreds of sash windows many thought were beyond repair. We will assess the damage and the repair requirements, taking your budget into consideration, and offer an honest opinion and expert advice.",

        image: Sash_Window_Repair,
        slug: "sash-window-repair",
      },
      {
        _id: 15,
        name: "Sash Window Replacement",
        description:
          "Ashmore Decorators can provide replacement sash windows. We will send out our highly skilled team to assess the job and provide a quote that is cost effective without compromising on quality. So, whether you want one sash window replacement or many, give us a call today.",
        image: Sash_Window_Replacement,
        slug: "sash-window-replacement",
      },
      {
        _id: 16,
        name: "Sash Window Restoration",
        description:
          "Our sash window restoration services utilise a special repair system that allows the original character of the window to be maintained. Combined with curative and preventative maintenance and repair methods, we can restore your timber windows to their former glory.",

        image: Sash_Window_Restoration,
        slug: "sash-window-restoration",
      },
      {
        _id: 17,
        name: "Skimming Specialists",
        description:
          "When you hire us you can rest assured that I am fully qualified, experienced and all of my plastering services are guaranteed. Pride is taken in every job to ensure all work is carried out to the highest specification, but we still take the time to ensure the work causes minimal disruption and mess. If you’re looking for a reputable skimming specialist in London who offers quality workmanship in all aspects of skimming then please get in touch today for a quick chat.",

        image: Skimming_Specialists,
        slug: "skimming-specialists",
      },
      {
        _id: 18,
        name: "Tiling Services",
        description:
          "Here at Ashmore Decorators we specialise in all aspects of tiling and are able to refresh your existing bathroom, kitchen or flooring. When you choose to undertake your tiling work, you are guaranteed to receive great service combined and quality work. Contact us today for any kind of tiling service and we will be happy to help.",
        image: Tiling_Services,
        slug: "tiling-services",
      },
      {
        _id: 19,
        name: "Wallpapering Services",
        description:
          "Hanging new wallpaper is a great way to refresh your home or business and we can advise on types of wallpaper as well as simply hanging wallpaper if you’ve already chosen it but need a hand getting it on the walls.Due to our experience and high level of craftsmanship, a great deal of our work comes from recommendation and repeat business.",
        image: Wallpapering_Services,
        slug: "wallpapering-services",
      },
    ],
  };

  // const blogData = {
  //   loaded: true,
  //   subdata: [
  //     {
  //       _id: 1,
  //       name: "Money back guarantee if not absolutely delighted!",
  //       image: blog1,
  //       description:
  //         "If you are familiar with advertisements for novelty domestic gadgets (See ‘B List’ TV channels around 3:15am) you",
  //       date: "October 28, 2023 / Tips",
  //     },
  //     {
  //       _id: 1,
  //       name: "Money back guarantee if not absolutely delighted!",
  //       image: blog2,
  //       description:
  //         "If you are familiar with advertisements for novelty domestic gadgets (See ‘B List’ TV channels around 3:15am) you",
  //       date: "October 28, 2023 / Tips",
  //     },
  //     {
  //       _id: 1,
  //       name: "Money back guarantee if not absolutely delighted!",
  //       image: blog3,
  //       description:
  //         "If you are familiar with advertisements for novelty domestic gadgets (See ‘B List’ TV channels around 3:15am) you",
  //       date: "October 28, 2023 / Tips",
  //     },
  //   ],
  // };

  const blogData = {
    loaded: true,
    subdata: [
      {
        _id: 1,
        image: blog1,
        month: "January",
        name: "Money back guarantee if not absolutely delighted!",
        date: "2023-10-28",
        author: "Author 1",
        description:
          "If you are familiar with advertisements for novelty domestic gadgets (See ‘B List’ TV channels around 3:15am) you ",
        postlinktitle: "Read More",
        url: "blog/blogdetails",
        slug: "money-back-guarantee-if-not-absolutely-delighted",
      },
      {
        id: 2,
        image: blog2,
        month: "October",
        name: "Oh no please NO!!!……not The Canned Presentation?!",
        slug: "oh-no-please-nonot-the-canned-presentation",
        date: "2023-10-28",
        author: "Author 2",
        description:
          "When the Spanish Inquisition (as reimagined by Monty Python’s Flying Circus) wanted to torture their victims into confessing",
        postlinktitle: "Read More",
        url: "/",
      },
      {
        id: 3,
        image: blog3,
        month: "January",
        name: "What’s a ‘Strategy’? ….it’s a…you- know…a ‘thingy’ !",
        date: "2023-10-28",
        author: "Author 3",
        slug: "whats-a-strategy-its-ayou-knowa-thingy",
        description:
          "The above answer to my question, “Could you define the word Strategy?” came from one of Asia’s top",
        postlinktitle: "Read More",
        url: "/",
      },
    ],
  };

  return (
    <>
      <HomeBanner data={bannerData} />
      <ServiceCard data={serviceData} />
      <div className="relative my-20">
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
            <div className="relative group bannerbtm">
              <div className="rounded-xl overflow-hidden aspect-[1/1] mb-5 border border-carnation-700 shadow-xl relative">
                <Image
                  src={Aboutimagefirst}
                  alt={""}
                  className="w-full h-full object-cover group-hover:transform group-hover:transition-all group-hover:scale-125 group-hover:duration-[1000ms] group-hover:ease-in"
                />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white p-4 transition-opacity duration-300 ease-in-out opacity-0 group-hover:opacity-100">
                  <div className="text-center">
                  <div className="text-sm">
                      We pride ourselves on strong project management and
                      planning, to ensure assignments are completed on time and
                      to the highest possible standards.
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative text-black font-extrabold text-center text-xl capitalize pb-3 mb-8 after:absolute after:border-b-2 after:border-carnation-600 after:bottom-0 after:left-0 after:w-28 after:h-1 after:right-0 after:mx-auto">
                Expert Decorating Services
              </div>
              <div className="flex justify-center">
                <Button
                  buttonLabel={"Our Decorating Services"}
                  buttonLabelClasses={"!text-sm"}
                  buttonType={"button"}
                  buttonClasses={
                    "!px-4 !py-6 hover:!bg-black transition-all duration-300 !rounded-md !text-sm !bg-carnation-600 group-hover:!bg-carnation-600"
                  }
                  buttonIcon={"fa-sharp fa-solid fa-arrow-right"}
                  buttonIconPosition={"left"}
                  buttonFunction={() => navigate("/service")}
                />
              </div>
            </div>

            <div className="relative group">
              <div className="rounded-xl overflow-hidden aspect-[1/1] mb-5 border border-carnation-700 shadow-xl">
                <Image
                  src={Aboutimagesecond}
                  alt={""}
                  className={
                    "w-full h-full object-cover group-hover:transform group-hover:transition-all group-hover:scale-125 group-hover:duration-[1000ms] group-hover:ease-in"
                  }
                />
              </div>
              <div className="relative text-black font-extrabold text-center text-xl capitalize pb-3 mb-8 after:absolute after:border-b-2 after:border-carnation-600 after:bottom-0 after:left-0 after:w-28 after:h-1 after:right-0 after:mx-auto">
                About the Decorators
              </div>
              <div className="flex justify-center">
                <Button
                  buttonLabel={"About Us"}
                  buttonLabelClasses={"!text-sm"}
                  buttonClasses={
                    "!px-4 !py-6 hover:!bg-black transition-all duration-300 !rounded-md !text-sm !bg-carnation-600 group-hover:!bg-carnation-600"
                  }
                  buttonIcon={"fa-sharp fa-solid fa-arrow-right"}
                  buttonIconPosition={"left"}
                  buttonType={"button"}
                  buttonFunction={() => navigate("/about")}
                />
              </div>
            </div>
            <div className="relative group">
              <div className="rounded-xl overflow-hidden aspect-[1/1] mb-5 border border-carnation-700 shadow-xl">
                <Image
                  src={Aboutimagethird}
                  alt={""}
                  className={
                    "w-full h-full object-cover group-hover:transform group-hover:transition-all group-hover:scale-125 group-hover:duration-[1000ms] group-hover:ease-in"
                  }
                />
              </div>
              <div className="relative text-black font-extrabold text-center text-xl capitalize pb-3 mb-8 after:absolute after:border-b-2 after:border-carnation-600 after:bottom-0 after:left-0 after:w-28 after:h-1 after:right-0 after:mx-auto">
                No Job Is Too Small
              </div>
              <div className="flex justify-center">
                <Button
                  buttonLabel={"Previous Projects"}
                  buttonLabelClasses={"!text-sm"}
                  buttonType={"button"}
                  buttonClasses={
                    "!px-4 !py-6 hover:!bg-black transition-all duration-300 !rounded-md !text-sm !bg-carnation-600 group-hover:!bg-carnation-600"
                  }
                  buttonIcon={"fa-sharp fa-solid fa-arrow-right"}
                  buttonIconPosition={"left"}
                  buttonFunction={() => navigate("/gallery")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Review></Review>
      <TestimonialBottomContext />
      <AllReviewList></AllReviewList>
      <div className="relative px-6 py-5 expert">
        <div className="bg-carnation-900/90 px-5 py-16 rounded-lg">
          <div className="relative text-white font-bold text-2xl text-center pb-3 mb-8 uppercase after:absolute after:border-b-2 after:border-white after:bottom-0 after:left-0 after:w-28 after:h-1 after:right-0 after:mx-auto">
            expert in decoration
          </div>
          <div className="text-white text-3xl capitalize w-[90%] mx-auto mb-5 mt-10 font-bold text-center">
            FOR EXPERT PAINTING AND DECORATING IN LONDON
          </div>
          <div className="text-white text-base capitalize w-[90%] mx-auto mb-8 text-center">
            CALL US TODAY 02071694208 OR  07868729221.
          </div>
          <div className="flex justify-center">
            <Button
              buttonLabel={"Call Us Today"}
              buttonLabelClasses={"!text-sm"}
              buttonClasses={
                "!px-4 !py-6 hover:!bg-black transition-all duration-300 !rounded-md !text-sm !bg-black"
              }
              buttonIcon={"fa-solid fa-phone-volume"}
              buttonIconPosition={"left"}
            />
          </div>
        </div>
      </div>

      <div className="relative my-28">
        <div className="grid grid-cols-1 xl:grid-cols-2 items-center">
          <div className="relative xl:absolute right-0 px-10 z-[2] w-full xl:w-[70%] bg-slate-200 py-12 rounded-xl">
            <div className="relative text-black font-bold text-2xl text-center pb-3 mb-8 uppercase after:absolute after:border-b-2 after:border-carnation-600 after:bottom-0 after:left-0 after:w-28 after:h-1 after:right-0 after:mx-auto">
              Decorators You Can Rely On
            </div>
            <div className="text-black font-normal mb-5">
              Ashmore Decorators is a well-established company with a wealth of
              experience in providing exceptional painting and decorating
              services. Our professionals specialise in both of the private and
              public sectors.
            </div>
            <div className="font-bold capitalize mb-2">
              We take pride in our work
            </div>
            <div className="text-black font-normal mb-5">
              Ashmore decorators has been delivering a smooth and simple
              decorating experience to our clients for over 30 years. We only
              hire experienced painters and decorators to ensure your home or
              business gets the finish it deserves.
            </div>
            <div className="font-bold capitalize mb-2">
              Our commercial and domestic painters are highly trained
            </div>
            <div className="text-black font-normal mb-5">
              All of our painters and decorators have experience within the
              private, commercial and public sectors. We focus on delivering a
              quality service prior to full payment being made by our clients.
            </div>
            <div className="font-bold capitalize mb-2">
              Leading commercial painting and decorating contractors
            </div>
            <div className="text-black font-normal mb-5">
              Ashmore Decorators is one of London leading commercial painting
              and decorating contractors, having successfully completed a wide
              variety of assignments including refurbishments, new builds, and
              fit outs for prominent construction companies.
            </div>
            <div className="flex justify-center">
              <Button
                buttonLabel={"Connect With Our Decorators Today"}
                buttonLabelClasses={"!text-sm"}
                buttonClasses={
                  "!px-4 !py-6 hover:!bg-black transition-all duration-300 !rounded-md !text-sm !bg-black"
                }
                buttonIcon={"fa-sharp fa-solid fa-arrow-right"}
                buttonIconPosition={"left"}
                buttonFunction={() => navigate("/contact")}
              />
            </div>
          </div>
          <div className="relative hidden xl:block">
            <Image
              src={Decoratorimg}
              alt={""}
              className={"w-full h-full object-cover"}
            />
          </div>
        </div>
      </div>
      <BlogCard data={blogData} />
      <div className="bg-carnation-900 py-10 mx-auto text-center">
        <h3 className="relative text-white font-bold text-2xl text-center pb-3 mb-8 after:absolute after:border-b-2 after:border-white after:bottom-0 after:left-0 after:w-28 after:h-1 after:right-0 after:mx-auto">
          Get A quote
        </h3>
        <p className="text-white text-base capitalize w-[90%] mx-auto mb-8">
          get in touch with ashmore decoratorsto begin discussing your external
          repair and redecoration project. we kick things off with a thorough
          quote. contact us at 02071694208 or 07868729221, or shoot us an
          email at info@ashmoredecorators.co.uk
        </p>
        <div className="max-w-[300px] h-16 mx-auto lg:mr-auto">
          <div className="flex justify-center items-center gap-8">
            <div className="w-40 overflow-hidden">
              <Image
                src={footerlogofirst}
                alt={"Logo"}
                width={"100%"}
                height={"100%"}
                effect={"blur"}
                classes={"object-contain lg:object-left"}
              />
            </div>
            <div className="w-28 h-28 overflow-hidden">
              <Image
                src={Footer_Second_Logo}
                alt={"Logo"}
                width={"100%"}
                height={"100%"}
                effect={"blur"}
                classes={"object-contain lg:object-left"}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-16">
          <Button
            buttonLabel={"Get Your Quote"}
            buttonLabelClasses={"!text-sm"}
            buttonClasses={
              "!px-4 !py-6 hover:!bg-black transition-all duration-300 !rounded-md !text-sm !bg-black"
            }
            buttonIcon={"fa-sharp fa-solid fa-arrow-right"}
            buttonIconPosition={"left"}
            buttonFunction={() => navigate("/contact")}
          />
        </div>
      </div>
      {/* <div className="bg-carnation-200 py-10">
        <div className="w-[632px] overflow-hidden mx-auto">
          <Image src={prize} alt={"Logo"} width={"100%"} height={"100%"} effect={"blur"} classes={"object-contain lg:object-left"} />
        </div>
      </div> */}
    </>
  );
};

export default Home;
