import React, { useState } from "react";
import { Link } from "react-router-dom";
import Image from "../elements/Image";
import Button from "../form/Button";
import DesignButton from "../extra/DesignButton";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";

const SingleService = ({ data }) => {
  const navigate = useNavigate();
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = (slug) => {
    // setShowFullDescription(!showFullDescription);
    navigate(`/service/${slug}`);
  };

  return (
    <>
      <div className="w-full flex flex-col bg-white rounded-4xl rounded-tl-none overflow-hidden transition-all duration-200 hover:shadow-lg">
        <div
          className={
            data?.smallImage
              ? "w-full data.smallImage?'70%': overflow-hidden rounded-4xl rounded-tl-none"
              : "aspect-[1/1] overflow-hidden rounded-4xl rounded-tl-none"
          }
        >
          <Image
            src={data && data?.image}
            alt={""}
            width={"100%"}
            height={"100%"}
            effect={"blur"}
            className={" object-cover"}
          />
        </div>
        <div className="py-6 px-4">
          <h1 className="font-Lexend text-org-theme text-sm lg:text-base xl:text-lg 2xl:text-xl 3xl:text-2xl font-medium leading-tight">
            {data?.name}
          </h1>

          <div className="text-xs xl:text-sm text-slate-600 my-4 text-ellips-desc line-clamp-2">
            <div
              dangerouslySetInnerHTML={{
                __html: showFullDescription
                  ? data?.description
                  : `${data?.description?.slice(0, 100)}...`,
              }}
            />
            <button
              onClick={() => toggleDescription(data?.slug)}
              className="text-org-theme underline text-xs xl:text-sm mt-2"
            >
              {showFullDescription ? "Show less" : "Read more"}
            </button>
          </div>
          <div className="relative">
            <div className="relative flex">
              <div className="text-slate-900">{data.dateText}</div>
            </div>
            <div className="">
              <div></div>
            </div>
          </div>
          <div className="flex items-center justify-between mt-auto">
            <DesignButton
              buttonClasses={"!px-8 !h-10 !ml-auto !bg-carnation-600"}
              buttonHasLink={false}
              buttonLabel={"View"}
              buttonLabelClasses={"text-sm"}
              buttonEffect={"filled"}
              buttonFunction={() => toggleDescription(data?.slug)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleService;
