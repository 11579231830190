import React from "react";
import img404 from "../../assets/404.png";
import Button from "../form/Button";

const PageNotFound = () => {
  return (
    <div className="block xl:flex h-full">
      <div className="w-full flex-wrap flex xl:flex items-center justify-end">
        <div className="relative w-full xl:w-[600px] h-auto xl:h-[600px]">
          <img
            width={800}
            height={800}
            src={img404}
            alt="invalid"
            className="w-full h-full object-contain object-center"
          />
        </div>
      </div>
      <div className="w-full flex flex-col justify-center">
        <div className="ml-0 xl:-ml-20 mt-5 xl:mt-20 px-4 xl:px-0 relative">
          <div className="text-3xl font-bold">
            It&apos;s seems you&apos;ve taken a wrong turn
          </div>
          <div className="font-medium my-3">
            the page you&apos;re looking for might have been misplaced,
            <br />
            lost in cyperspace, or it simply doesn&apos;t exiist
          </div>
          <div className="relative xl:absolute bottom-0 xl:-bottom-60 right-0 xl:right-[300px] text-9xl xl:text-[200px] text-slate-200/40 font-extrabold">
            404
          </div>
          <Button
            buttonHasLink={true}
            buttonLink={"/"}
            buttonClasses={"!w-fit !bg-org-theme !border-org-theme !text-white"}
            buttonLabel={"Go to home page"}
          />
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
