import { createSlice, createAction } from "@reduxjs/toolkit";

export const authLogin = createAction("authLogin");
export const authUserSetup = createAction("authUserSetup");
export const authSettingsSetup = createAction("authSettingsSetup");
export const authLogout = createAction("authLogout");

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    user: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(authLogin, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(authLogout, (state, action) => {
        state.loading = false;
        state.user = null;
      })
      .addCase(authUserSetup, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(authSettingsSetup, (state, action) => {
        state.loading = false;
        state.settings = action.payload;
      });
  },
});
