import React, { useState } from "react";
import ReviewCard from "./ReviewCard";
import google from "../../assets/images/google.jpg";
import yell from "../../assets/images/yell.jpg";
import resellers from "../../assets/images/resellers.jpg";
import facebook from "../../assets/images/facebook.jpg";
import checktrade from "../../assets/images/checktrade.jfif";


const AllReviewList = () => {
  const reviewsList = [
    {
      _id: 1,
      rating: 5,
      title: "Dillon B. - June 30, 2024",
      shortDescription: "5 out of 5 stars",
      description:
        "Services provided: tiling, fire place refurbishment, and decorating Overall did a great job and we’re very happy with our beautifully decorated and refurbished home!",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 2,
      rating: 5,
      title: "Daniel G. - April 14, 2024",
      shortDescription: "5 out of 5 stars",
      description:
        "Excellent job carefully and patiently done. To be fair, the passage of time is needed to fully evaluate, but our sense is Aziz is skilled and experienced decorator who arrived promptly and did the job asked. Would highly recommend.",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 3,
      rating: 5,
      title: "Mark Thomas - April 2, 2024",
      shortDescription: "5 out of 5 stars",
      description: "Great service",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 4,
      rating: 5,
      title: "Mark T. - April 2, 2024",
      shortDescription: "5 out of 5 stars",
      description: "Very good Aziz was very professional",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 5,
      rating: 5,
      title: "Edward G. - February 5, 2024",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz was awesome to deal with! He was very easy to communicate with, gave us an initial quote quickly with a very fair price. Aziz is very trustworthy and reliable, completing our job whilst we were away on holiday. Aziz is an expert, very happy with the quality and overall finish. Would definitely have Aziz again and recommend him to anyone!",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 6,
      rating: 5,
      title: "Edward Garside - February 4, 2024",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz was awesome to work with. Quick to get back to me regarding initial quote, he completed the job whilst we were on holiday. Very trustworthy, reliable and a fair price. Would definitely have him again!",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 7,
      rating: 5,
      title: "Simon J. - October 16, 2023",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz was always quick to respond and accommodate our ever changing requirements. Thanks a lot",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 8,
      rating: 5,
      title: "Elisa P. - August 16, 2023",
      shortDescription: "5 out of 5 stars",
      description:
        "We had a great experience. Aziz is very professional, polite, precise and on time. He came around to provide a quote (very reasonable and honest compared to others received), he completed the work in a timely fashion and to a very good standard, leaving our place clean and tidy. He is very pleasant and discrete to have around and added some additional touches to the required tasks. Highly recommended!",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 9,
      rating: 5,
      title: "Gethin Thomas - August 1, 2023",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz recently completed a comprehensive redecoration of our house, at very short notice. He did an exceptionally thorough job. He was a pleasure to deal with.",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 10,
      rating: 5,
      title: "Alina Seli - May 2, 2023",
      shortDescription: "5 out of 5 stars",
      description:
        "Positive: Quality, Value 5 stars ⭐️ very good skill and worked to complete the job long hours thank you",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 11,
      rating: 5,
      title: "Gareth Pugh - February 11, 2023",
      shortDescription: "5 out of 5 stars",
      description:
        "We had a wonderful experience working with Aziz and his team at Ashmore Decorators. Aziz is gracious, highly professional and very fair with his pricing. We have had the walls in nearly every room in our new flat redecorated, along with other services such as restoration of a Victorian fireplace, handyman jobs and plumbing jobs. Aziz has assembled a great team to see to all kinds of work. We would gladly recommend Ashmore Decorators to anybody needing work done in Kilburn.",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 12,
      rating: 5,
      title: "Raina Brands - December 5, 2022",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz decorated three rooms in our house, including hanging wall paper. He was a pleasure to work with - friendly, responsive and on time ... not to mention his work is superb. I could not recommend him more highly.",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 13,
      rating: 5,
      title: "Vincent S. - December 4, 2022",
      shortDescription: "5 out of 5 stars",
      description:
        "I highly recommend Aziz, he carried out fabulous quality work which ranged from painting, plastering, damp proofing, repairing windows and tiling. You can tell he has had years of building experience as he frequently came up with solutions and suggestions as a result of my never-ending requests! Not only did he offer an excellent service and high standard of workmanship, he was punctual and worked extremely hard to finish in time given time constraints which was much appreciated! Aziz is also a very honest gentleman and transparent with his work pipeline so we could plan accordingly. It was an absolute pleasure to work with him, will undoubtedly work with him again in future!",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 14,
      rating: 5,
      title: "James Dodd - October 19, 2022",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz was great and his work was flawless. He got the job done with very little notice and worked late nights to get it done in a short timeframe. I would definitely recommend.",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 15,
      rating: 5,
      title: "Finlay Coulter. - September 6, 2022",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz was wonderful - he came in at 24hrs notice to paint my entire filming studio, filled the wall gaps and was all round a great help! Lovely guy - will certainly be calling him again for anything I need in the future.",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 16,
      rating: 5,
      title: "JamesD-1867 - October 19, 2022",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz was great and his work was flawless. He got the job done with very little notice and worked late nights to get it done in a short timeframe. I would definitely recommend.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 17,
      rating: 5,
      title: "Julien S. - May 16, 2022",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz did an amazing job painting our flat! He was really helpful and professionals throughout. Highly recommended for any painting job.",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "Response: Aziz G. - May 16, 2022",
          description:
            "Hi Julien , It was pleasure working with you , thank you very much for using Ashmore Decorators. Best wishes , Aziz",
        },
      ],
    },
    {
      _id: 18,
      rating: 5,
      title: "Ylva G. - May 28, 2022",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz was wonderful! Very professional, communicative and friendly! Quick service as well, would absolutely recommend.",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "Response from the owner - May 28, 2022",
          description:
            "Hi Ylva It was great working with you , Thank You for your time in reviewing us. Kind Regards, Aziz",
        },
      ],
    },
    {
      _id: 19,
      rating: 5,
      title: "Alex E. - April 3, 2022",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz is a professional painter that won’t leave you disappointed. He works thoroughly, always comes on time and visibly has a wealth of experience when it comes to painting. Can only recommends",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 20,
      rating: 5,
      title: "Cari O. - April 3, 2022",
      shortDescription: "5 out of 5 stars",
      description:
        "It was an excellent experience. So response and reliable. Did a great job under time pressure.",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 21,
      rating: 5,
      title: "MARCUS V. - April 1, 2022",
      shortDescription: "5 out of 5 stars",
      description:
        "Ashmore. More Craftsmanship. More Care. More Value. Ashmore are a small independent local business in West London. They specialise in painting decorating, plastering and tiling all at master craftsman level for all internal and external works. Aziz is a hands-on Managing Director who leads from the front regardless of the job size and he is committed to delivering a first class product at outstanding value for all of his customers. Ashmore decorators and plastering business are talented, professional, trustworthy and honest team focussed on supplying confidence and a quality product. Pricing is economical and modest and the team manage a great job every time. Ashmore work tirelessly and are always on time and available on site to ensure that everything is clean and tidy. In business the Product is important but so are the people. This is where Ashmore excel. Aziz is a very decent and happy man. I’d happy pay extra daily fee to have his cheery and sunny disposition around our home.",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 22,
      rating: 5,
      title: "MarcV-22 - March 24, 2022",
      shortDescription: "5 out of 5 stars",
      description:
        "Repair and rendering walls and stairwell on Victorian stucco villa. Plastering sanding down ahead of undercoat and weatherproof top coat. Full paint and repairs on front door.Aziz is a top man in his field delivering a first class product at outstanding value. Ashmore deliver a professional and friendly service. Pricing is economical and the team are totally committed to delivering and great job, going up and above the call of duty with regards to technical spec, materials, detailing and final delivery. The team work tirelessly and on time and ensure that everything is clean and tidy on site and therefore I can fully recommend Ashmore for any domestic or commercial decorating or plastering. A great job offering confidence and quality every time. As a side, Aziz is also a very decent and kind man and its has been an unexpected pleasure to have his cheery and sunny disposition around our home. We will be commissioning Ashmore on all or decorating needs moving forwards.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 23,
      rating: 5,
      title: "Paula C. - March 8, 2022",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz painted my living room and kitchen and he did an excellent job, on top of that he was super helpful and accommodating of all my requests. Will hire him again when I need decorating work again. Thank you Aziz!",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 24,
      rating: 5,
      title: "Michelle G. - November 4, 2021",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz and Ashmore decorators are just the type of company that you want working at your home. Apart from being a most experienced decorator of long standing his painting is second to none. Aziz did both the inside and the outside of my home which hadn’t been touched for over 10 years he proved himself to be both dependable, diligent and a wizard with the paint brush. I would not hesitate to recommend him.",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 25,
      rating: 5,
      title: "Colin Thorpe - August 21, 2021",
      shortDescription: "5 out of 5 stars",
      description:
        "Paint and paper job. Excellent service/quality from a great guy... Cheers",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 26,
      rating: 5,
      title: "Colin T. - August 19, 2021",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz painted and papered our back bedroom. ...It was a fantastic job that has revitalised the room. Added to which, He's a great guy, helpful tidy etc but to cap it all, He's really flexible. Hopefully, He'll be available to do our future projects if you don't all book him first...! Cheers Aziz",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 27,
      rating: 5,
      title: "ColinT-283 - August 18, 2021",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz painted and papered our back bedroom. ...It was a fantastic job that has revitalised the room. Added to which, He's a great guy, helpful tidy etc but to cap it all, He's really flexible.Hopefully, He'll be available to do our future projects if you don't all book him first...!CheersAziz",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 28,
      rating: 5,
      title: "Colin Thorpe - August 15, 2021",
      shortDescription: "5 out of 5 stars",
      description:
        "Terrific service from Aziz. ...can't recommend more highly....diligent, careful, flexible for a great price...Thanks!",
      image: facebook,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 29,
      rating: 5,
      title: "Sacha B. - May 31, 2021",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz was very professional and did a fantastic job in our flat. He had to do several rooms and finished them all very well and even sorted out some scuff marks we had on another wall which was really appreciated. Overall a great job - I would definitely recommend him!",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 30,
      rating: 5,
      title: "D S. - May 22, 2021",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz came to paint the walls in my apartment. I was very impressed, the work was done well and on time. Aziz is very accommodating and was able to work around my schedule. He is equally reasonable with his prices and charges fair rates. Would highly recommend and use Aziz again.",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 31,
      rating: 5,
      title: "Jacek D. - May 22, 2021",
      shortDescription: "5 out of 5 stars",
      description:
        "I employed Aziz to do a paint repair job in my flat (to cover up ink stains and damage on the walls). The work was done perfectly and quickly. Additionally, I found that Aziz offers competitive, reasonable prices and is very flexible with time slots. He is a really down to earth man and was a pleasure to have working on my flat. I would highly recommend Aziz for any paint job.",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 32,
      rating: 5,
      title: "Shaun Flanagan - May 21, 2021",
      shortDescription: "5 out of 5 stars",
      description:
        "Excellent work completed on time and on budget. Also a great pleasure to work with Aziz with his friendly nature and clear communications concerning the project activities. I highly recommend using Ashmore Decorators!",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 33,
      rating: 5,
      title: "Sarathy K. - May 12, 2021",
      shortDescription: "5 out of 5 stars",
      description:
        "Very professional and friendly service. Aziz was very meticulous, efficient and would highly recommend him.",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 34,
      rating: 5,
      title: "Shaun Flanagan F. - May 4, 2021",
      shortDescription: "5 out of 5 stars",
      description:
        "Excellent work completed on time and on budget. Also a great pleasure to work with Aziz with his friendly nature and clear communications concerning the project activities. I highly recommend using Ashmore Decorators!",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 35,
      rating: 5,
      title: "Kat S - April 15, 2021",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz is a very good decorator. He worked miracles after a roof leak and really helped to make my flat look good. I was so impressed with his work that I hired him for further work on my property.",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 36,
      rating: 5,
      title: "Katherine S. - April 12, 2021",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz is honest and hard working. He made my flat look good again after we had roof issues. He is skilled and trustworthy, was able to redecorate my whole flat in a competent manner. I would hire him again.",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 37,
      rating: 5,
      title: "Shamim K. - March 30, 2021",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz did a brilliant job for our flat, with great skill care and attention to detail. He gave us advice about the best paint the most reliable materials and spotted issues which we didn’t see and made them good. Aziz always listens to his customers concerns and works with them to complete a project. We highly recommend Ashmore for any painting / decorating projects.",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 38,
      rating: 5,
      title: "ShaminK - March 29, 2021",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz did a brilliant job for our flat, with great skill care and attention to detail. He gave us advice about the best paint the most reliable materials and spotted issues which we didn’t see and made them good. Aziz always listens to his customers concerns and works with them to complete a project. We highly recommend Ashmore for any painting / decorating projects.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 39,
      rating: 5,
      title: "Dane Moravek - January 21, 2021",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz was very professional & friendly. He did a fantastic job in putting our very complicated wallpaper.. Highly recommend Aziz if you want great quality of work done. Vishal",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 40,
      rating: 5,
      title: "HannahB-463 - November 11, 2020",
      shortDescription: "5 out of 5 stars",
      description:
        "He has done a great job, covering a mould stain that occurred after a leak. He took time to do the job properly-coming back 3 times in total to make sure the mark was covered.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 41,
      rating: 5,
      title: "HannahB-463 - November 11, 2020",
      shortDescription: "5 out of 5 stars",
      description:
        "He has done a great job, covering a mould stain that occurred after a leak. He took time to do the job properly-coming back 3 times in total to make sure the mark was covered.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 42,
      rating: 5,
      title: "ChloeH-282 - October 22, 2020",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz was fantastic and very patient with my restorative plans for a piece of furniture. He also wallpapered one of the rooms perfectly and in no time at all. He is a pleasure to work with and I will most definitely book him again In the future.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 43,
      rating: 5,
      title: "ChloeH-282 - October 22, 2020",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz was fantastic and very patient with my restorative plans for a piece of furniture. He also wallpapered one of the rooms perfectly and in no time at all. He is a pleasure to work with and I will most definitely book him again In the future.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 44,
      rating: 5,
      title: "ChloeK-71 - October 19, 2020",
      shortDescription: "5 out of 5 stars",
      description:
        "We had a wonderful experience with Ashmore decorators. Really professional, great craftsmanship and Aziz was lovely to deal with. On top of that they arrived in PPE and kept their masks on. Will definitely use again.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 45,
      rating: 5,
      title: "ChloeK-71 - October 19, 2020",
      shortDescription: "5 out of 5 stars",
      description:
        "We had a wonderful experience with Ashmore decorators. Really professional, great craftsmanship and Aziz was lovely to deal with. On top of that they arrived in PPE and kept their masks on. Will definitely use again.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 50,
      rating: 5,
      title: "PeterB-1681 - October 11, 2020",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz and his team did a terrific job re-decorating a spare bedroom (including sanding wardrobes, re-plastering and re-painting) and painting all the internal doors in my property. He was very responsive, polite and efficient and I would have no hesitation in using him again.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 51,
      rating: 5,
      title: "PeterB-1681 - October 11, 2020",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz and his team did a terrific job re-decorating a spare bedroom (including sanding wardrobes, re-plastering and re-painting) and painting all the internal doors in my property. He was very responsive, polite and efficient and I would have no hesitation in using him again.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 52,
      rating: 5,
      title: "SuzanneW-166 - October 3, 2020",
      shortDescription: "5 out of 5 stars",
      description:
        "Completed a complicated wallpapering project on time and with great skill. Aziz never fails to deliver.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 53,
      rating: 5,
      title: "SuzanneW-166 - October 3, 2020",
      shortDescription: "5 out of 5 stars",
      description:
        "Completed a complicated wallpapering project on time and with great skill. Aziz never fails to deliver.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    // {
    //   _id: 54,
    //   rating: 4,
    //   title: "NicholasB-185 - September 23, 2020",
    //   shortDescription: "4 out of 5 stars",
    //   description:
    //     "Aziz is a friendly, reliable and completely trustworthy painter and decorator. His quote was very reasonable and while is rare for any large project to go 100% as planned, he was communicative throughout and additional work required was also priced very fairly. Unavoidable snags were dealt with promptly and without any issue. I have no hesitation in recommending Ashmore Decorators for any project, large or small.",
    //   image: yell,
    //   response: [
    //     {
    //       _id: 1,
    //       title: "",
    //       description: "",
    //     },
    //   ],
    // },
    // {
    //   _id: 55,
    //   rating: 4,
    //   title: "NicholasB-185 - September 23, 2020",
    //   shortDescription: "4 out of 5 stars",
    //   description:
    //     "Aziz is a friendly, reliable and completely trustworthy painter and decorator. His quote was very reasonable and while is rare for any large project to go 100% as planned, he was communicative throughout and additional work required was also priced very fairly. Unavoidable snags were dealt with promptly and without any issue. I have no hesitation in recommending Ashmore Decorators for any project, large or small.",
    //   image: yell,
    //   response: [
    //     {
    //       _id: 1,
    //       title: "",
    //       description: "",
    //     },
    //   ],
    // },
    {
      _id: 56,
      rating: 5,
      title: "Martin S. - July 28, 2020",
      shortDescription: "5 out of 5 stars",
      description: "Great service, great price for quality of service",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 57,
      rating: 5,
      title: "Sarah K. - July 21, 2020",
      shortDescription: "5 out of 5 stars",
      description:
        "We have been very happy with Aziz's work. He was very easy to work with and did a fantastic job. We will definitely be I touch again when we have more decorating works and would highly recommend him.",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    // {
    //   _id: 58,
    //   rating: 4.5,
    //   title: "Dan C. - July 21, 2020",
    //   shortDescription: "4.5 out of 5 stars",
    //   description:
    //     "A good finish. Friendly member of staff. Very happy with painting job.",
    //   image: checktrade,
    //   response: [
    //     {
    //       _id: 1,
    //       title: "",
    //       description: "",
    //     },
    //   ],
    // },
    // {
    //   _id: 59,
    //   rating: 4.5,
    //   title: "Nick C. - July 15, 2020",
    //   shortDescription: "4.5 out of 5 stars",
    //   description:
    //     "Aziz has been great to work with. Fair priced and reliable. Also open to doing extra work beyond painting. Will use again.",
    //   image: checktrade,
    //   response: [
    //     {
    //       _id: 1,
    //       title: "",
    //       description: "",
    //     },
    //   ],
    // },
    {
      _id: 60,
      rating: 5,
      title: "Charlotte T. - July 8, 2020",
      shortDescription: "5 out of 5 stars",
      description:
        "Very p0sitive and pleased and impressed by the quality of the work",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 61,
      rating: 5,
      title: "ZacS-11 - June 14, 2020",
      shortDescription: "5 out of 5 stars",
      description:
        "Very high standard of work. Aziz was punctual, tidy, efficient. Would use again",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 62,
      rating: 5,
      title: "ZacS-11 - June 14, 2020",
      shortDescription: "5 out of 5 stars",
      description:
        "Very high standard of work. Aziz was punctual, tidy, efficient. Would use again",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 63,
      rating: 5,
      title: "EveO-10 - May 23, 2020",
      shortDescription: "5 out of 5 stars",
      description:
        "Fantastic painting job done by Aziz. Delighted with the result as the work was done with care and consideration.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 64,
      rating: 5,
      title: "EveO-10 - May 23, 2020",
      shortDescription: "5 out of 5 stars",
      description:
        "Fantastic painting job done by Aziz. Delighted with the result as the work was done with care and consideration.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 65,
      rating: 5,
      title: "LmB-4 - May 16, 2020",
      shortDescription: "5 out of 5 stars",
      description:
        "Am very happy with the redecorating work done in my living room, bedroom and bathroom - Aziz is very professional and has great attention to detail. Would highly recommend",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 66,
      rating: 5,
      title: "LmB-4 - May 16, 2020",
      shortDescription: "5 out of 5 stars",
      description:
        "Am very happy with the redecorating work done in my living room, bedroom and bathroom - Aziz is very professional and has great attention to detail. Would highly recommend",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    // {
    //   _id: 67,
    //   rating: 4,
    //   title: "PamJ-52 - March 6, 2020",
    //   shortDescription: "4 out of 5 stars",
    //   description:
    //     "He arrived on time and did his work with care and professionally. I would use him again and recommend him",
    //   image: yell,
    //   response: [
    //     {
    //       _id: 1,
    //       title: "",
    //       description: "",
    //     },
    //   ],
    // },
    // {
    //   _id: 68,
    //   rating: 4,
    //   title: "PamJ-52 - March 6, 2020",
    //   shortDescription: "4 out of 5 stars",
    //   description:
    //     "He arrived on time and did his work with care and professionally. I would use him again and recommend him",
    //   image: yell,
    //   response: [
    //     {
    //       _id: 1,
    //       title: "",
    //       description: "",
    //     },
    //   ],
    // },
    {
      _id: 69,
      rating: 5,
      title: "EvelynY-2 - January 28, 2020",
      shortDescription: "5 out of 5 stars",
      description: "Nice and professional",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 70,
      rating: 5,
      title: "EvelynY-2 - January 28, 2020",
      shortDescription: "5 out of 5 stars",
      description: "Nice and professional",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 71,
      rating: 5,
      title: "KathyH-75 - November 30, 2019",
      shortDescription: "5 out of 5 stars",
      description:
        "I highly recommend Aziz. He gave me a very reasonable price for the painting and repairs to my flat. He’s a very fast neat painter, due to his vast experience painting tube stations. He’s easy to have around , extremely polite and trustworthy. As a landlord, I will always recommend his services. Outstanding.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 72,
      rating: 5,
      title: "LaureB-2 - November 11, 2019",
      shortDescription: "5 out of 5 stars",
      description:
        "Amazing painter ! Really good work and nice man. Highly recommended !",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 73,
      rating: 5,
      title: "LaureB-2 - November 11, 2019",
      shortDescription: "5 out of 5 stars",
      description:
        "Amazing painter ! Really good work and nice man. Highly recommended !",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 74,
      rating: 5,
      title: "MartinS-725 - October 24, 2019",
      shortDescription: "5 out of 5 stars",
      description:
        "Fantastic work, great jon on two rooms we needed painted in college",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 75,
      rating: 5,
      title: "MartinS-725 - October 24, 2019",
      shortDescription: "5 out of 5 stars",
      description:
        "Fantastic work, great jon on two rooms we needed painted in college",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 76,
      rating: 5,
      title: "HishamK-1 - October 23, 2019",
      shortDescription: "5 out of 5 stars",
      description:
        "An excellent and professional company to work with. Very reliable and friendly. Highly recommend their services!!!",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 77,
      rating: 5,
      title: "HishamK-1 - October 23, 2019",
      shortDescription: "5 out of 5 stars",
      description:
        "An excellent and professional company to work with. Very reliable and friendly. Highly recommend their services!!!",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 78,
      rating: 5,
      title: "Angela Kingdom - October 19, 2019",
      shortDescription: "5 out of 5 stars",
      description:
        "Very professional service. All communications answered in a timely fashion. The work was completed to a high standard.",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 79,
      rating: 5,
      title: "MuE - July 24, 2019",
      shortDescription: "5 out of 5 stars",
      description:
        "Excellent service provided by Aziz at Ashmore Decorators a very thorough job was done I was very happy with the service. I would recommend the service this company provides to others.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 80,
      rating: 5,
      title: "MuE - July 24, 2019",
      shortDescription: "5 out of 5 stars",
      description:
        "Excellent service provided by Aziz at Ashmore Decorators a very thorough job was done I was very happy with the service. I would recommend the service this company provides to others.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 81,
      rating: 5,
      title: "JackP-171 - April 11, 2019",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz did an amazing job on our new house. Always on time and also helped with other jobs outside of the painting & decorating. Will definitely be recommending to friends and using again.Jack",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 82,
      rating: 5,
      title: "JackP-171 - April 11, 2019",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz did an amazing job on our new house. Always on time and also helped with other jobs outside of the painting & decorating. Will definitely be recommending to friends and using again.Jack",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 83,
      rating: 5,
      title: "JubilyB - January 5, 2019",
      shortDescription: "5 out of 5 stars",
      description: "Excellent service, great worker to",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 84,
      rating: 5,
      title: "JubilyB - January 5, 2019",
      shortDescription: "5 out of 5 stars",
      description: "Excellent service, great worker to",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 85,
      rating: 5,
      title: "Tom Thomas - October 19, 2018",
      shortDescription: "5 out of 5 stars",
      description:
        "Very friendly and professional and the painting was done to an excellent standard. Fantastic value for money-I will definitely use Ashmore again.",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 86,
      rating: 5,
      title: "Nikki Bella - October 19, 2018",
      shortDescription: "5 out of 5 stars",
      description:
        "Very good decorator thanks very much I am very pleased with work.",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    // {
    //   _id: 87,
    //   rating: 4,
    //   title: "IreneR-20 - September 2, 2018",
    //   shortDescription: "4 out of 5 stars",
    //   description:
    //     "Very good worker always on time and very polite job well done and very pleased",
    //   image: yell,
    //   response: [
    //     {
    //       _id: 1,
    //       title: "",
    //       description: "",
    //     },
    //   ],
    // },
    // {
    //   _id: 88,
    //   rating: 4,
    //   title: "IreneR-20 - September 2, 2018",
    //   shortDescription: "4 out of 5 stars",
    //   description:
    //     "Very good worker always on time and very polite job well done and very pleased",
    //   image: yell,
    //   response: [
    //     {
    //       _id: 1,
    //       title: "",
    //       description: "",
    //     },
    //   ],
    // },
    {
      _id: 89,
      rating: 5,
      title: "IsaacT-4 - August 28, 2018",
      shortDescription: "5 out of 5 stars",
      description: "High quality work. Reasonably priced.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 90,
      rating: 5,
      title: "IsaacT-4 - August 28, 2018",
      shortDescription: "5 out of 5 stars",
      description: "High quality work. Reasonably priced.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 91,
      rating: 5,
      title: "MargaretC-131 - July 31, 2018",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz is trustworthy, reliable and a skilled craftsman. We employed him for the length of the entire refurbishment and only he was able to deal with those last minute nightmare jobs. He is always polite and gets along well with other workman. We cannot recommend him highly enough.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 92,
      rating: 5,
      title: "MargaretC-131 - July 31, 2018",
      shortDescription: "5 out of 5 stars",
      description:
        "Aziz is trustworthy, reliable and a skilled craftsman. We employed him for the length of the entire refurbishment and only he was able to deal with those last minute nightmare jobs. He is always polite and gets along well with other workman. We cannot recommend him highly enough.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 93,
      rating: 5,
      title: "BeeC-8 - July 24, 2018",
      shortDescription: "5 out of 5 stars",
      description:
        "I found Ashmore Decorators to be very reliable , always turned up on time , the work was of a high standard , I was kept informed of how the work was progressing and they were very helpful and courteous .Prices were very reasonable",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    // {
    //   _id: 94,
    //   rating: 4,
    //   title: "ClaireL-274 - July 24, 2018",
    //   shortDescription: "4 out of 5 stars",
    //   description:
    //     "I can't comment on the quality of the work as I haven't seen it but during my short dealings with Aziz in arranging the work he has been easy to communicate with and extremely polite - a pleasure to deal with.",
    //   image: yell,
    //   response: [
    //     {
    //       _id: 1,
    //       title: "",
    //       description: "",
    //     },
    //   ],
    // },
    {
      _id: 95,
      rating: 5,
      title: "BeeC-8 - July 24, 2018",
      shortDescription: "5 out of 5 stars",
      description:
        "I found Ashmore Decorators to be very reliable , always turned up on time , the work was of a high standard , I was kept informed of how the work was progressing and they were very helpful and courteous .Prices were very reasonable",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    // {
    //   _id: 96,
    //   rating: 4,
    //   title: "ClaireL-274 - July 24, 2018",
    //   shortDescription: "4 out of 5 stars",
    //   description:
    //     "I can't comment on the quality of the work as I haven't seen it but during my short dealings with Aziz in arranging the work he has been easy to communicate with and extremely polite - a pleasure to deal with.",
    //   image: yell,
    //   response: [
    //     {
    //       _id: 1,
    //       title: "",
    //       description: "",
    //     },
    //   ],
    // },
    {
      _id: 97,
      rating: 5,
      title: "GJayU - July 11, 2018",
      shortDescription: "5 out of 5 stars",
      description:
        "Very friendly and professional and the painting was done to an excellent standard. Fantastic value for money-I will definitely use Ashmore again.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 98,
      rating: 5,
      title: "GJayU - July 11, 2018",
      shortDescription: "5 out of 5 stars",
      description:
        "Very friendly and professional and the painting was done to an excellent standard. Fantastic value for money-I will definitely use Ashmore again.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 99,
      rating: 5,
      title: "HimansuU - June 30, 2018",
      shortDescription: "5 out of 5 stars",
      description:
        "What a friendly guy. He agreed a reasonable price and done the work the following day - even though it was a Sunday. Work was done to a very high standard. Highly recommended.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 100,
      rating: 5,
      title: "HimansuU - June 30, 2018",
      shortDescription: "5 out of 5 stars",
      description:
        "What a friendly guy. He agreed a reasonable price and done the work the following day - even though it was a Sunday. Work was done to a very high standard. Highly recommended.",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 101,
      rating: 5,
      title: "BlueBucket23-1 - March 20, 2018",
      shortDescription: "5 out of 5 stars",
      description:
        "The work I had done I was very happy with, they was nice friendly people and completed the work on time leaving it looking just as I wanted, they left no mess and was very good value for money I would definitely use them again...",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 102,
      rating: 5,
      title: "BlueBucket23-1 - March 20, 2018",
      shortDescription: "5 out of 5 stars",
      description:
        "The work I had done I was very happy with, they was nice friendly people and completed the work on time leaving it looking just as I wanted, they left no mess and was very good value for money I would definitely use them again...",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 103,
      rating: 5,
      title: "Sau - February 14, 2018",
      shortDescription: "5 out of 5 stars",
      description:
        "Very professional, efficient and fast service. I highly recommend them to everyone and will use them again in future if the need ever arose! Thank you so much!",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 104,
      rating: 5,
      title: "Sau - February 14, 2018",
      shortDescription: "5 out of 5 stars",
      description:
        "Very professional, efficient and fast service. I highly recommend them to everyone and will use them again in future if the need ever arose! Thank you so much!",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 105,
      rating: 5,
      title: "YellowBowl86-1 - February 4, 2018",
      shortDescription: "5 out of 5 stars",
      description:
        "Very loyal and hardworking company, work completed to a high standard, would definitely recommend as they provide an extremely good service!",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 106,
      rating: 5,
      title: "YellowBowl86-1 - February 4, 2018",
      shortDescription: "5 out of 5 stars",
      description:
        "Very loyal and hardworking company, work completed to a high standard, would definitely recommend as they provide an extremely good service!",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 107,
      rating: 5,
      title: "DannyP-51 - October 1, 2017",
      shortDescription: "5 out of 5 stars",
      description:
        "Very good decorater thanks very much I am very pleased with work.Daniel Parlour",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 108,
      rating: 5,
      title: "DannyP-51 - October 1, 2017",
      shortDescription: "5 out of 5 stars",
      description:
        "Very good decorater thanks very much I am very pleased with work.Daniel Parlour",
      image: yell,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 109,
      rating: 5,
      title: "Julien Sanders - May 16, 2022",
      shortDescription: "5 out of 5 stars",
      description: "",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 110,
      rating: 5,
      title: "Dominic .. - October 19, 2021",
      shortDescription: "5 out of 5 stars",
      description: "",
      image: checktrade,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
    {
      _id: 111,
      rating: 5,
      title: "Shamim Kashem - March 29, 2021",
      shortDescription: "5 out of 5 stars",
      description: "",
      image: google,
      response: [
        {
          _id: 1,
          title: "",
          description: "",
        },
      ],
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const reviewsPerPage = 6;

  const totalPages = Math.ceil(reviewsList.length / reviewsPerPage);
  const currentReviews = reviewsList.slice(
    (currentPage - 1) * reviewsPerPage,
    currentPage * reviewsPerPage
  );

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const generatePaginationRange = (currentPage, totalPages) => {
    const delta = 2;
    const range = [];
    const startPage = Math.max(2, currentPage - delta);
    const endPage = Math.min(totalPages - 1, currentPage + delta);
    range.push(1);
    if (startPage > 2) range.push("...");
    for (let i = startPage; i <= endPage; i++) {
      range.push(i);
    }
    if (endPage < totalPages - 1) range.push("...");
    if (totalPages > 1) range.push(totalPages);

    return range;
  };

  const paginationRange = generatePaginationRange(currentPage, totalPages);

  return (
    <div className="container mx-auto p-4 mb-20">
      {/* <h2 className="text-2xl font-bold mb-4">Customer Reviews</h2> */}
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 gap-4">
        {currentReviews.map((review) => (
          <ReviewCard key={review._id} review={review} />
        ))}
      </div>
      <div className="flex-wrap flex lg:flex space-y-2 md:space-y-0 justify-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          className="px-2 md:px-4 py-1 md:py-2 mx-1 md:mx-2 bg-carnation-600 rounded hover:bg-gray-400 text-white !h-8 xl:!h-auto"
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {paginationRange.map((page, index) => (
          <button
            key={index}
            onClick={() => typeof page === "number" && handlePageChange(page)}
            className={`px-2 md:px-4 py-1 md:py-2 mx-1 !mt-0 rounded !h-8 xl:!h-10 ${currentPage === page
              ? "bg-black text-white"
              : "bg-carnation-600 text-white hover:bg-gray-400"
              }`}
            disabled={typeof page !== "number"}
          >
            {page}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          className="px-4 py-2 mx-2 !mt-0 bg-carnation-600 rounded hover:bg-gray-400 text-white !h-9 xl:!h-auto"
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default AllReviewList;
