import React, { useEffect, useState } from "react";
import Image from "./elements/Image";
import StarRating from "./review/StarRating";

const Popup = ({ reviews }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.subdata.length);
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [reviews.subdata.length]);

  return (
    <div className="popup bg-white rounded-lg shadow-lg w-96 h-24 mx-auto overflow-hidden relative">
      <div className="relative w-full h-full overflow-hidden rounded-lg">
        <div
          className="flex transition-transform duration-700 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {reviews.subdata.map((review, index) => (
            <div
              key={index}
              className="w-full flex-shrink-0 flex flex-col p-2 transition-all duration-200 hover:shadow-lg font-bold"
            >
              <div className="flex items-center gap-2">
                <div className="flex-shrink-0 rounded-full overflow-hidden">
                  <Image src={review.icon} alt="Icon" className="w-8 h-8 sm:w-10 sm:h-10" />
                </div>
                <div className="flex-grow">
                  <h4 className="text-xs sm:text-sm md:text-base font-semibold text-black leading-tight">
                    {review.name}
                  </h4>
                  <div className="text-xs sm:text-sm text-slate-600">
                    {review.date}
                  </div>
                </div>
                <div className="p-1 flex items-center justify-end">
                  <StarRating rating={review.rating} />
                </div>
              </div>

              <div className="text-xs sm:text-sm md:text-base text-slate-800 mt-1 text-center">
                {review.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Popup;
