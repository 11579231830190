import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: "",
};

const employeeSlice = createSlice({
  name: "employeeSearch",
  initialState,
  reducers: {
    getEmployeeSearch: (state, action) => {
        state.data = action.payload
    },
  },
});

export const {getEmployeeSearch} = employeeSlice.actions;
export default employeeSlice.reducer