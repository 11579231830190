import React, { useState } from "react";

const ReviewCard = ({ review }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  return (
    <div className="max-w-sm w-full px-5 md:px-10 py-8 lg:max-w-full lg:flex mb-4 shadow-lg rounded-lg overflow-hidden transition-all duration-300 bg-slate-100 flex md:block justify-between hover:scale-95">
      <div
        className="!h-28 !w-28 lg:h-auto lg:w-48 flex-none bg-cover bg-center mb-5 md:mb-0"
        style={{ backgroundImage: `url(${review.image})` }}
      ></div>
      <div className="p-0 md:p-4 pl-4 !pt-0 flex flex-col justify-between leading-normal">
        <div className="mb-8">
          <div className="text-black font-bold text-xl mb-2">
            {review.title}
          </div>
          <p className="text-gray-700 text-base">{review.shortDescription}</p>
          <p className="text-gray-700 text-base mt-2">
            {showFullDescription
              ? review.description
              : `${review.description.slice(0, 100)}...`}
            <button
              onClick={() => setShowFullDescription(!showFullDescription)}
              className="text-blue-500 ml-2"
            >
              {showFullDescription ? "Read Less" : "Read More"}
            </button>
          </p>
        </div>
        {review.response.length > 0 && (
          <div className="mt-2">
            <button
              onClick={() => setShowResponse(!showResponse)}
              className="!bg-black px-3 py-3 !text-white text-sm rounded-lg"
            >
              {showResponse ? "Hide Response" : "Show Response"}
            </button>
            <div
              className={`${showResponse ? "block" : "hidden"
                } transition-all duration-300`}
            >
              <h3 className="text-gray-800 font-semibold">Response:</h3>
              {review.response.map((res) => (
                <div key={res._id} className="mt-2">
                  <p className="text-gray-700">
                    {res.title || "No Response Here"}
                  </p>
                  <p className="text-gray-700">{res.description}</p>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewCard;
