import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import DefaultHeader from "../shared/DefaultHeader";
import DefaultFooter from "../shared/DefaultFooter";

const DefaultLayout = () => {


  const footerData = {
    logo: "settings && settings?.logo?.url ? settings?.logo?.url : Logo",
    about:
      "Copyright © 2024 Ashmore Decorators. The content on this website is owned by us and our licensors. Do not copy any content (including images) without our consent. ",
    socialMenu: [
      {
        _id: 1,
        name: "Facebook",
        link: "https://www.facebook.com/AshmoreDecorators/",
        icon: "fa-brands fa-square-facebook",
      },
      {
        _id: 2,
        name: "Whatsapp",
        link: `https://wa.me/${447868729221}`,
        icon: "fa-brands fa-whatsapp",
      },
      {
        _id: 3,
        name: "Photos",
        link: "https://g.co/kgs/8cXd3w8",
        icon: "fa-solid fa-image",
      },
      // {
      //   _id: 3,
      //   name: "Twitter",
      //   link: "https://twitter.com/",
      //   icon: "fa-twitter",
      // },
    ],
    columnOne: {
      title: "Serivces",
      menu: [
        {
          _id: 1,
          name: "Commercial Painting Contractors",
          link: "/service",
        },
        {
          _id: 3,
          name: "Coving Services",
          link: "/service",
        },
        {
          _id: 5,
          name: "Decorating Services",
          link: "/service",
        },
        {
          _id: 6,
          name: "Exterior Painting",
          link: "/service",
        },
        {
          _id: 7,
          name: "Exterior Renderings",
          link: "/service",
        },
        {
          _id: 8,
          name: "Interior Painting",
          link: "/service",
        },
        { _id: 9, name: "Sash Window Replacement", link: "/service" },
        { _id: 10, name: "Sash Window Restoration", link: "/service" },
        { _id: 11, name: "Sash Window Repair", link: "/service" },
      ],
    },
    columnTwo: {
      title: "Services",
      menu: [
        {
          _id: 1,
          name: "Laminated Flooring",
          link: "/service",
        },
        { _id: 2, name: "Local Builders", link: "/service" },
        { _id: 3, name: "London Underground Refurbishments", link: "/service" },
        { _id: 4, name: "Mastic Services", link: "/service" },
        { _id: 5, name: "Painting Contractors", link: "/service" },
        { _id: 6, name: "Plastering Services", link: "/service" },
        { _id: 7, name: "Residential Decorating", link: "/service" },
        { _id: 9, name: "Skimming Specialists", link: "/service" },
        { _id: 8, name: "Tiling Services", link: "/service" },
        { _id: 8, name: "Wallpapering Services", link: "/service" },
      ],
    },
    columnThree: {
      title: "About Us",
      menu: [
        { _id: 1, name: "Home", link: "/" },
        { _id: 2, name: "Gallery", link: "/gallery" },
        { _id: 3, name: "Our Stories", link: "/our-stories" },
        { _id: 4, name: "Blogs", link: "/post" },

        { _id: 5, name: "Contact", link: "/contact" },
        // { _id: 5, name: "Success Stories", link: "/success-stories" },
      ],
    },
    contact: {
      title: "Contact Us",
      subdata: [
        {
          _id: 1,
          label: "Email",
          data: "info@ashmoredecorators.co.uk",
          icon: "fa-envelope",
          type: "mailto",
        },
        {
          _id: 2,
          label: "Contact No.",
          data: "07868729221",
          icon: "fa-phone-volume",
          type: "tel",
        },
        {
          _id: 3,
          label: "Address",
          data: (
            <>
              <p>{"74 Ashmore Rd, London,"}</p>
              <p>{"W9 3DG"}</p>
            </>
          ),
          icon: "fa-location-dot",
          type: "address",
        },
      ],
    },
    copyright: "Designed By @weconnectmedia",
  };
  return (
    <>
      <DefaultHeader />
      <Outlet />
      <DefaultFooter data={footerData} />
    </>
  );
};

export default DefaultLayout;
