import React, { useState, useEffect } from "react";
import Image from "../components/elements/Image";
import Button from "../components/form/Button";
import { Link } from "react-router-dom";
import { classNames } from "../helpers/classNames";
import { useNavigate } from "react-router-dom/dist/umd/react-router-dom.development";
import { useDispatch } from "react-redux";
import Footer_Second_Logo from "./../assets/images/footer_Second_Logo.png";
import Footer_Second_Circle_Logo from "./../assets/images/footer_Second_Circle_logo.png";
import LOGO from "./../assets/mainLogo.png";

const DefaultHeader = ({ data, user }) => {
  const [isOpen, setOpen] = useState(false);
  const [menuToggle, setMenuToggle] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const socialMedia = [
    {
      _id: 1,
      name: "Facebook",
      icon: "fa-brands fa-square-facebook",
      url: "https://www.facebook.com/",
    },
    {
      _id: 2,
      name: "Whatsapp",
      url: `https://wa.me/${447868729221}`,
      icon: "fa-brands fa-whatsapp",
    },
    {
      _id: 3,
      name: "Photos",
      url: "https://g.co/kgs/8cXd3w8",
      icon: "fa-solid fa-image",
    },
    // {
    //   _id: 3,
    //   name: "Twitter",
    //   icon: "fa-x-twitter",
    //   url: "https://twitter.com/",
    // },
    // {
    //   _id: 4,
    //   name: "Instagram",
    //   icon: "fa-instagram",
    //   url: "https://www.instagram.com/",
    // },
  ];

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const menuData = [
    { _id: 1, name: "Home", link: "/" },
    { _id: 2, name: "About", link: "/about" },
    { _id: 3, name: "Services", link: "/service" },
    { _id: 4, name: "Gallery", link: "/gallery" },
    { _id: 5, name: "Our Stories", link: "/our-stories" },
    { _id: 6, name: "Blogs", link: "/post" },
    { _id: 7, name: "Contact", link: "/contact" },
  ];

  const onPageHaneler = (item) => {
    navigate(item);
    setMenuToggle(false);
  };

  return (
    <>
      {!menuToggle && (
        <>
          <div className="bg-carnation-600 py-2 relative z-20">
            <div className="w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex items-center">
              <div className="grid grid-cols-12 gap-1 md:gap-3 xl:gap-2 w-full items-center">
                <div className="col-span-12 xl:col-span-9 space-x-1 md:space-x-5 flex justify-center xl:justify-start">
                  <a
                    href="tel:07868729221"
                    className="text-white text-xs md:text-xl lg:text-2xl border-r border-white pr-4 flex justify-start items-center"
                  >
                    <i className="fa-sharp fa-solid fa-phone-volume mr-2"></i>{" "}
                    <span className="hidden md:block">Call Us: </span>07868729221
                  </a>
                  <a
                    href="mailto:info@ashmoredecorators.co.uk"
                    className="text-white text-xs md:text-2xl flex justify-start items-center"
                  >
                    <i className="fa-solid fa-envelope mr-2"></i>{" "}
                    <span className="hidden md:block">Email Us:</span>
                    info@ashmoredecorators.co.uk
                  </a>
                </div>
                <div className="col-span-12 xl:col-span-3">
                  <ul className="flex justify-center xl:justify-end space-x-2 mt-2 md:mt-0">
                    {socialMedia.map((item) => (
                      <li
                        key={item._id}
                        className="flex bg-black w-7 h-7 text-center rounded border border-white hover:bg-carnation-800 transition-all duration-200 "
                      >
                        <a
                          href={item.url}
                          target="_blank"
                          // rel="noreferrer"
                          className="text-xs text-white w-full pt-1.5"
                        >
                          <i
                            className={classNames(" cursor-pointer", item.icon)}
                          ></i>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <header
        className={`fixed top-0 left-0 w-full transition-all duration-500 z-10 ${
          isScrolled
            ? "bg-white shadow-lg py-3"
            : "bg-transparent pt-[68px] md:pt-[76px] xl:pt-10"
        }`}
      >
        <div
          className={`w-full px-4 sm:px-5 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex items-center ${
            isScrolled ? "" : "bg-[#d8d3c8] pt-3 pb-3 md:pb-5 mt-1 rounded-b-lg"
          }`}
        >
          <div className="flex justify-between w-full items-center">
            <div className="flex justify-between items-center  gap-5">
              <div className="h-14 xl:h-16">
                <Link
                  to={"/"}
                  className="block w-32 xl:w-full h-auto xl:h-full"
                >
                  <Image
                    src={LOGO}
                    alt={"Logo"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-contain object-left"}
                  />
                </Link>
              </div>
              <div className="w-20 h-20">
                <Link
                  to={"/"}
                  className="block w-20 xl:w-full h-auto xl:h-full"
                >
                  <Image
                    src={Footer_Second_Circle_Logo}
                    alt={"Logo"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-contain lg:object-left"}
                  />
                </Link>
              </div>
            </div>
            <div className="xl:hidden">
              <Button
                buttonClasses={"!bg-secondaryColor !px-0 w-12"}
                buttonHasLink={false}
                buttonFunction={() => setMenuToggle(!menuToggle)}
                buttonIcon={"fa-regular fa-bars"}
                buttonIconPosition={"left"}
              />
            </div>
            <div
              className={classNames(
                "fixed z-10 xl:relative top-0 xl:top-auto left-0 xl:left-auto w-72 xl:w-auto h-full xl:h-auto bg-white xl:bg-transparent flex flex-col xl:flex-row xl:items-center xl:justify-end gap-8 py-5 xl:py-0 shadow-2xl xl:shadow-none xl:!translate-x-0 transition:all duration-300 p-10",
                menuToggle ? "translate-x-0" : "-translate-x-full"
              )}
            >
              <div className="flex items-center justify-center xl:hidden border-b border-slate-700 pb-5">
                <div className="h-14 mx-auto">
                  <Image
                    src={LOGO}
                    alt={"Logo"}
                    width={"100%"}
                    height={"100%"}
                    effect={"blur"}
                    classes={"object-contain object-center"}
                  />
                </div>
              </div>
              <ul className="block xl:flex items-center space-y-4 xl:space-y-0 xl:space-x-10">
                {menuData.map((link) => (
                  <li key={link._id}>
                    <div
                      onClick={() => onPageHaneler(link.link)}
                      className="cursor-pointer text-lg"
                    >
                      {link.name}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div
              className={classNames(
                "fixed z-[9] w-full h-full top-0 left-0 transition-all duration-300 bg-carnation-500/50",
                menuToggle ? "opacity-100 visible" : "opacity-0 invisible"
              )}
              onClick={() => setMenuToggle(!menuToggle)}
            ></div>
          </div>
        </div>
      </header>
    </>
  );
};

export default DefaultHeader;
