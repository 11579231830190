import React, { useEffect, useState } from 'react';
import { classNames } from "../../helpers/classNames";

const Tabs = ({tabs, design, ...props}) => {
  const [selectedTab, setSelectedTab] = useState({})
  
  const handleTabSelection = (tab = {}) => {
    setTimeout(() => {
      let activeTab = [];
      let filteredTab = tabs.filter((tab) => activeTab.includes(tab._id))[0];
      if(filteredTab){
        activeTab = [...activeTab.filter((actab) => actab !== filteredTab._id), tab?._id]
      } else {
        activeTab = [...activeTab, tab?._id]
      }
      setSelectedTab(tab);
    }, 100)
  }

  useEffect(() => {
    if (tabs.length) {
      setSelectedTab(tabs[0])
    }
  }, [tabs]);
  
  return (
    <>
      <div className="relative">
        <nav className={classNames("flex overflow-auto ", design === "style"?"border-b border-slate-200 px-4 xl:px-8 gap-5 xl:gap-6":"gap-3 xl:gap-4")}>
          {tabs.map((item, index) => (
            <button 
              type="button" 
              className={classNames(
                "relative py-3 xl:py-4  rounded-t-2xl transition-all duration-200", 
                design === "style"?"border-b-2 border-transparent px-0":"px-6 xl:px-12",
                (item._id === selectedTab?._id)?(design === "style"?"!border-carnation-500":"bg-white"):(design === "style"?"bg-white":"bg-slate-200"),
                
              )} 
              key={index} 
              onClick={() => handleTabSelection(item)}
            >
              <div className={classNames(
                "whitespace-nowrap",
                design === "style"?"text-sm font-semibold":"text-sm xl:text-base font-medium",
                (item._id === selectedTab?._id)?(design === "style"?"!text-carnation-500":"text-black"):"text-black"
              )}>{item.name}</div>
            </button>
          ))}
        </nav>
        <div className="relative rounded-2xl !rounded-tl-none bg-white py-4 xl:py-8 px-4 xl:px-8">{selectedTab?.component}</div>
      </div>
    </>
  );
};

export default Tabs;