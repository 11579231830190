import React from 'react';
import Image from "../elements/Image";
import StarRating from './StarRating';

const SingleStory = ({ data }) => {
  return (
    <div className="w-full flex flex-col bg-white rounded-2xl py-6 px-8 xl:py-10 xl:px-12 overflow-hidden transition-all duration-200 hover:shadow-lg">
      <div className="flex items-center gap-5">
        <div className="flex-grow-0 flex-shrink-0 rounded-full overflow-hidden">
          <Image src={data.icon} alt="Icon" className="w-10 h-10" />
        </div>
        <div className="w-full flex-grow flex-shrink">
          <h4 className="text-base xl:text-lg font-Lexend text-black font-semibold leading-tight">{data.name}</h4>
          <div className="text-xs xl:text-sm text-slate-600">{data.date}</div>
        </div>
      </div>
      <div className="p-4">
        <StarRating rating={data.rating} />
      </div>
      <div className="text-xs xl:text-sm text-slate-600 mt-4">{data.description}</div>
    </div>
  );
};

export default SingleStory;
