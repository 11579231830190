import React from "react";
import { classNames } from "../helpers/classNames";
import Image from "../components/elements/Image";
import Gallerybg from "../assets/images/gallery-bg.png";
import story1 from ".././../src/assets/images/story1.jpg";
import story2 from ".././../src/assets/images/story2.jpg";
import story3 from ".././../src/assets/images/story3.jpg";
import story4 from ".././../src/assets/images/story4.jpg";
import story5 from ".././../src/assets/images/story5.jpg";
import story6 from ".././../src/assets/images/story6.jpg";
import story7 from ".././../src/assets/images/story7.jpg";
import story8 from ".././../src/assets/images/story8.jpg";
import story9 from ".././../src/assets/images/story9.jpg";
import story10 from ".././../src/assets/images/story10.jpg";
import Ashmore_Own_Gallery1 from ".././../src/assets/images/ashmore-own-gallery1.webp";
import { Link } from "react-router-dom";
import prize from "./../assets/images/prize.jpg";

const OurStories = () => {
  const storyImage = [
    {
      image: Ashmore_Own_Gallery1,
      alt: "",
      title:
        "Ashmore Decorators Honour To Decorate The Hollywood Starbrian Cox's Home...!",
    },
    {
      image: story1,
      alt: "",
      title:
        "Painting and Decorating London Hippodrome Theatre at Leicester Square",
    },
    {
      image: prize,
      alt: "",
      title:
        "Health & Safety Gold Standard",
    },
    { image: story2, alt: "", title: "London Hippodrome in Leicester square" },
    { image: story3, alt: "", title: "Before" },
    { image: story4, alt: "", title: "After" },
    { image: story5, alt: "", title: "Commercial painting and decorating" },
    { image: story6, alt: "", title: "Pride to have decorated Hollywood start Brian Cox" },
    { image: story7, alt: "", title: "Wall paper hanging" },
    { image: story8, alt: "", title: "Wall paper hanging" },
    { image: story9, alt: "", title: "Job completed" },
    { image: story10, alt: "", title: "Job done" },
  ];
  return (
    <>
      <div
        className={classNames(
          "relative z-[2] w-full h-[200px] lg:h-[300px] xl:h-[400px] overflow-hidden rounded-b-3xl mb-10",
          "after:content-[''] after:absolute after:w-full after:h-full after:bg-gradient-to-t after:from-black/80 after:bottom-0 after:left-0 after:z-[1]"
        )}
      >
        <Image
          src={Gallerybg}
          alt={""}
          width={"100%"}
          height={"100%"}
          effect={"blur"}
          className={" object-cover object-top"}
        />
        <h4 className="absolute left-0 right-0 mx-auto text-center text-white text-lg lg:text-xl xl:text-3xl capitalize bottom-20 lg:bottom:20 xl:bottom-40 z-10 font-bold">
          Our Stories
        </h4>
      </div>

      <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
        <div className="bg-carnation-100 px-10 py-16 -top-8 rounded-xl relative z-[9] -mt-8 xl:-mt-20">
          <div className="text-lg lg:text-3xl text-black font-extrabold mb-5 text-center capitalize">
            Our Journey: From Humble Beginnings to Masterful Creations
          </div>
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {storyImage.map((image, index) => (
              <Link
                key={index}
                href=""
                className="block relative overflow-hidden z-[1] group"
              >
                <div className="aspect-[1/1] overflow-hidden rounded-b-none shadow-lg">
                  <Image
                    src={image.image}
                    alt={image.alt}
                    className="w-full h-full object-cover object-top"
                    layout="fill"
                  />
                </div>
                <div className="relative flex items-center justify-center text-sm font-semibold text-white text-center p-3 bg-carnation-800 h-20">
                  {image.title}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default OurStories;
