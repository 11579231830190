import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import AuthLayout from "./layouts/AuthLayout";
// import ProtectedRoute from "./routes/ProtectedRoute";

import DefaultLayout from "./layouts/DefaultLayout";
import Home from "./pages/Home";
import FreeAuthRoute from "./routes/FreeAuthRoute";
import PageNotFound from "./components/extra/PageNotFound";
import About from "./pages/About";
import ServiceDetails from "./pages/ServiceDetails";
import Contact from "./pages/Contact";
import BlogDetails from "./pages/BlogDetails";
import Blog from "./pages/BlogList";
import Gallery from "./pages/Gallery";
import Service from "./pages/Service";
import OurStories from "./pages/OurStories";



const CreateRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {/* {/ Front routing /} */}
          <Route path="/" element={<DefaultLayout isSearch={true} />}>
            <Route
              path="/"
              exact
              element={
                <FreeAuthRoute>
                  <Home />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/about" element={<DefaultLayout isSearch={true} />}>
            <Route
              path="/about"
              exact
              element={
                <FreeAuthRoute>
                  <About />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/service" element={<DefaultLayout isSearch={true} />}>
            <Route
              path="/service"
              exact
              element={
                <FreeAuthRoute>
                  <Service />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/service/:slug" element={<DefaultLayout isSearch={true} />}>
            <Route
              path="/service/:slug"
              exact
              element={
                <FreeAuthRoute>
                  <ServiceDetails />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/gallery" element={<DefaultLayout isSearch={true} />}>
            <Route
              path="/gallery"
              exact
              element={
                <FreeAuthRoute>
                  <Gallery />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/post" element={<DefaultLayout isSearch={true} />}>
            <Route
              path="/post"
              exact
              element={
                <FreeAuthRoute>
                  <Blog />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/post/:slug" element={<DefaultLayout isSearch={true} />}>
            <Route
              path="/post/:slug"
              exact
              element={
                <FreeAuthRoute>
                  <BlogDetails />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/contact" element={<DefaultLayout isSearch={true} />}>
            <Route
              path="/contact"
              exact
              element={
                <FreeAuthRoute>
                  <Contact />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/our-stories" element={<DefaultLayout isSearch={true} />}>
            <Route
              path="/our-stories"
              exact
              element={
                <FreeAuthRoute>
                  <OurStories />
                </FreeAuthRoute>
              }
            />
          </Route>
          {/* <Route path="*" element={<DefaultLayout />}> */}
            <Route path="*" element={<PageNotFound />} />
          {/* </Route> */}
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default CreateRoutes;
