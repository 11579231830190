import { configureStore } from "@reduxjs/toolkit";
import reducer from "./reducer";
import api from "./middleware/api";
const ConfigureStore = () =>
  configureStore({
    reducer,
    // middleware: [
    //     ...getDefaultMiddleware({ serializableCheck: false}),
    //     api
    // ]
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
    api,
  });

export default ConfigureStore;
