import React from "react";
import { classNames } from "../helpers/classNames";
import Image from "../components/elements/Image";
import Gallerybg from "../assets/images/gallery-bg.png";
import { Link } from "react-router-dom";
import Button from "../components/form/Button";
// import { Fancybox } from "@fancyapps/ui/dist/fancybox.umd.js";
// import "@fancyapps/ui/dist/fancybox/fancybox.css";
import blog1 from ".././../src/assets/images/blog1.webp";
import blog2 from ".././../src/assets/images/blog2.webp";
import blog3 from ".././../src/assets/images/blog3.webp";
import gallery1 from ".././../src/assets/images/gallery1.jpeg";
import gallery2 from ".././../src/assets/images/gallery2.jpeg";
import gallery3 from ".././../src/assets/images/gallery3.jpeg";
import gallery4 from ".././../src/assets/images/gallery4.jpeg";
import gallery5 from ".././../src/assets/images/gallery5.jpeg";
import gallery6 from ".././../src/assets/images/gallery6.jpeg";
import gallery7 from ".././../src/assets/images/gallery7.jpeg";
import gallery8 from ".././../src/assets/images/gallery8.jpeg";
import Ashmore_Own_Gallery1 from ".././../src/assets/images/ashmore-own-gallery1.webp";
import Ashmore_Own_Gallery2 from ".././../src/assets/images/ashmore-own-gallery2.webp";
import Ashmore_Own_Gallery3 from ".././../src/assets/images/ashmore-own-gallery3.webp";
import Ashmore_Own_Gallery4 from ".././../src/assets/images/ashmore-own-gallery4.webp";
import Ashmore_Own_Gallery5 from ".././../src/assets/images/ashmore-own-gallery5.webp";
import Ashmore_Own_Gallery6 from ".././../src/assets/images/ashmore-own-gallery6.webp";
import Ashmore_Own_Gallery7 from ".././../src/assets/images/ashmore-own-gallery7.webp";
import Ashmore_Own_Gallery8 from ".././../src/assets/images/ashmore-own-gallery8.webp";
import Ashmore_Own_Gallery9 from ".././../src/assets/images/ashmore-own-gallery9.webp";
import Ashmore_Own_Gallery10 from ".././../src/assets/images/ashmore-own-gallery10.webp";
import Ashmore_Own_Gallery11 from ".././../src/assets/images/ashmore-own-gallery11.webp";
import Ashmore_Own_Gallery12 from ".././../src/assets/images/ashmore-own-gallery12.webp";
import Ashmore_Own_Gallery13 from ".././../src/assets/images/ashmore-own-gallery13.webp";
import Ashmore_Own_Gallery14 from ".././../src/assets/images/ashmore-own-gallery14.webp";
import Ashmore_Own_Gallery15 from ".././../src/assets/images/ashmore-own-gallery15.webp";
import Ashmore_Own_Gallery18 from ".././../src/assets/images/ashmore-own-gallery18.jfif";
import prize from "./../assets/images/prize.jpg";
import story1 from ".././../src/assets/images/story1.jpg";


const Gallery = () => {
  const galleryImage = [
    { image: gallery1, alt: "Image 31" },
    { image: gallery2, alt: "Image 32" },
    { image: gallery3, alt: "Image 33" },
    { image: gallery4, alt: "Image34" },
    { image: gallery5, alt: "Image 35" },
    { image: gallery6, alt: "Image 36" },
    { image: gallery7, alt: "Image 37" },
    { image: gallery8, alt: "Image 38" },
    { image: prize, alt: "Image 1" },
    // { image: blog2, alt: "Image 2" },
    // { image: blog3, alt: "Image 3" },
    { image: Ashmore_Own_Gallery1, alt: "Image 4" },
    { image: Ashmore_Own_Gallery2, alt: "Image 5" },
    { image: Ashmore_Own_Gallery3, alt: "Image 6" },
    { image: Ashmore_Own_Gallery18, alt: "Image 7" },
    { image: Ashmore_Own_Gallery5, alt: "Image 8" },
    { image: Ashmore_Own_Gallery6, alt: "Image 9" },
    { image: Ashmore_Own_Gallery7, alt: "Image 10" },
    { image: Ashmore_Own_Gallery8, alt: "Image 11" },
    { image: Ashmore_Own_Gallery9, alt: "Image 12" },
    { image: Ashmore_Own_Gallery10, alt: "Image 13" },
    { image: Ashmore_Own_Gallery11, alt: "Image 14" },
    { image: Ashmore_Own_Gallery12, alt: "Image 15" },
    { image: Ashmore_Own_Gallery13, alt: "Image 16" },
    { image: Ashmore_Own_Gallery14, alt: "Image 17" },
    { image: Ashmore_Own_Gallery15, alt: "Image 18" },
  ];

  return (
    <>
      <div
        className={classNames(
          "relative z-[2] w-full h-[200px] lg:h-[300px] xl:h-[400px] overflow-hidden rounded-b-3xl mb-10",
          "after:content-[''] after:absolute after:w-full after:h-full after:bg-gradient-to-t after:from-black/80 after:bottom-0 after:left-0 after:z-[1]"
        )}
      >
        <Image
          src={Gallerybg}
          alt={""}
          width={"100%"}
          height={"100%"}
          effect={"blur"}
          className={" object-cover"}
        />
        <h4 className="absolute left-0 right-0 mx-auto text-center text-white text-lg lg:text-xl xl:text-3xl capitalize bottom-20 lg:bottom:20 xl:bottom-40 z-10 font-bold">
          Gallery
        </h4>
      </div>

      <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
        <div className="bg-carnation-100 px-10 py-16 -top-8 rounded-xl relative z-[9] -mt-8 xl:-mt-20">
          <div className="text-lg lg:text-3xl text-black font-extrabold mb-5 text-center">
            Ashmore Decorators are your local interior decoration experts in
            London
          </div>
          <div className="text-sm font-normal text-slate-500 text-center mb-10">
            If it’s time to give your home a makeover, why not contact the
            interior decoration experts at Ashmore Decorators in London for
            quality work at great prices? You can also check out what our
            customers think of our work on the testimonials page.
          </div>
          <div className="p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {galleryImage.map((image, index) => (
              <Link
                key={index}
                href={""}
                className="block relative overflow-hidden z-[1] gallery group"
              >
                <div className="aspect-[1/1] overflow-hidden rounded shadow-lg">
                  <Image
                    src={image?.image}
                    alt={image.alt}
                    className="w-full h-full object-cover"
                  />
                </div>

                <div className="absolute top-0 left-0 bottom-0 right-0 flex items-center opacity-0 transform translate-y-[-50px] transition-all ease-in duration-[.8s] z-[5] group-hover:opacity-100 group-hover:transform group-hover:translate-y-[0] group-hover:delay-[.5s]">
                  <span className="relative top-0 left-0 bottom-0 right-0 font-semibold text-md text-carnation-500 mx-auto text-center pt-2 rounded-sm">
                    {/* <i class="fa-regular fa-magnifying-glass"></i> */}
                    Gallery
                  </span>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-org-darkBlue py-10 mx-auto text-center">
        <h3 className="relative text-white font-bold text-2xl text-center pb-3 mb-8 after:absolute after:border-b-2 after:border-white after:bottom-0 after:left-0 after:w-28 after:h-1 after:right-0 after:mx-auto capitalize">
          wallpaper hanging and decorative finishes
        </h3>
        <p className="text-white text-base capitalize w-[90%] mx-auto">
          we are able to hang many types of wallpapers and coverings. we have
          previously worked with ranges from laura ashley to sanderson. It is
          essential that you pick the right quality of wallpaper in order to
          achieve the finish that you desire, so when making your selection we
          recommend that you purchase superior quality wallpaper and seek
          professional advice.
        </p>
      </div>
      <div className="bg-carnation-900/90 px-5 py-16 rounded-lg">
        <div className="relative text-white font-bold text-2xl text-center pb-3 mb-8 uppercase after:absolute after:border-b-2 after:border-white after:bottom-0 after:left-0 after:w-28 after:h-1 after:right-0 after:mx-auto">
          expert in decoration
        </div>
        <div className="text-white text-3xl capitalize w-[90%] mx-auto mb-5 mt-10 font-bold text-center">
          FOR EXPERT PAINTING AND DECORATIN IN LONDON
        </div>
        <div className="text-white text-base capitalize w-[90%] mx-auto mb-8 text-center">
          CALL US TODAY 020 7169 4208 OR 07868729221.
        </div>
        <div className="flex justify-center">
          <Button
            buttonLabel={"Call Us Today"}
            buttonLabelClasses={"!text-sm"}
            buttonClasses={
              "!px-4 !py-6 hover:!bg-black transition-all duration-300 !rounded-md !text-sm !bg-black"
            }
            buttonIcon={"fa-solid fa-phone-volume"}
            buttonIconPosition={"left"}
          />
        </div>
      </div>
    </>
  );
};

export default Gallery;
