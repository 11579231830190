import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: "",
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    getSearch: (state, action) => {
        state.data = action.payload
    },
  },
});

export const {getSearch} = searchSlice.actions;
export default searchSlice.reducer