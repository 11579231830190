import React from "react";
import { classNames } from "../../helpers/classNames";

const Search = ({
  placeholder,
  divClasses,
  inputClasses,
  iconClasses,
  iconPosition = "left",
  icon = "fa-regular fa-magnifying-glass",
  onSearch = () => {},
}) => {
  return (
    <>
      <div
        className={classNames(
          "relative flex h-10 w-full bg-slate-50 rounded-md border border-slate-200 overflow-hidden",
          divClasses
        )}
      >
        {iconPosition === "left" && (
          <div
            className={classNames(
              "w-10 h-10 min-w-[40px] flex items-center justify-center text-slate-400 bg-slate-100",
              iconClasses
            )}
          >
            <i className={classNames("fa-fw", icon)}></i>
          </div>
        )}
        <input
          type="text"
          onChange={(e) => onSearch(e.target.value)}
          placeholder={placeholder}
          className={classNames(
            "!border-0 w-full !ring-0 bg-transparent text-slate-600 text-sm p-0 pr-3",
            inputClasses
          )}
        />
        {iconPosition === "right" && (
          <div
            className={classNames(
              "w-10 h-10 min-w-[40px] flex items-center justify-center text-slate-400 bg-slate-100",
              iconClasses
            )}
          >
            <i className={classNames("fa-fw", icon)}></i>
          </div>
        )}
      </div>
    </>
  );
};

export default Search;
