import { combineReducers } from "redux";

import settingsSlice from "./siteSettings/settingsSlice";
import profileSlice from "./Profile/profileSlice";
import { authSlice } from "./authSlice";
import searchSlice from "./chartSearch/searchSlice";
import employeeSlice from "./employeeSearch/employeeSlice";

export default combineReducers({
  auth: authSlice.reducer,
  profile: profileSlice,
  settings: settingsSlice,
  search: searchSlice,
  employeeSearch: employeeSlice,
});
